import { Bytes } from "firebase/firestore";
import Hls, { FragmentLoaderContext, HlsConfig, Loader, LoaderCallbacks, LoaderConfiguration, LoaderContext, LoaderStats, PlaylistLoaderContext } from "hls.js";
import { FC, useEffect, useState } from "react";
import * as uuid from "uuid";
import { VideoPlayer } from "./VideoPlayer";

const sendMessageWhenConnected = (socket: WebSocket, message: string | ArrayBufferLike | ArrayBufferView | Blob) => {
  return new Promise<void>((resolve, reject) => {
    if (socket.readyState === WebSocket.OPEN) {
      // WebSocketが既に接続されている場合はそのままメッセージを送信
      socket.send(message);
      resolve();
    } else {
      // WebSocketが接続されていない場合は接続イベントを待機
      socket.addEventListener('open', () => {
        socket.send(message);
        resolve();
      });
      // エラーハンドリング
      socket.addEventListener('error', (error) => {
        reject(error);
      });
    }
  });
}

const getFilenameFromURL = (url: string) => {
  const filePath = new URL(url).pathname;
  let filename = filePath.substring(filePath.lastIndexOf('/') + 1);
  return filename.split('?')[0];
}

const blobToArrayBuffer = (blob: Blob) => {
  return new Promise<ArrayBuffer>((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      resolve(reader.result as ArrayBuffer);
    };
    reader.onerror = reject;
    reader.readAsArrayBuffer(blob);
  });
}

const splitArrayBufferAtNull = (arrayBuffer: ArrayBuffer): [ArrayBuffer, ArrayBuffer|null] => {
  const uint8Array = new Uint8Array(arrayBuffer);

  // ヌル文字が最初に出現するインデックスを検索
  const nullIndex = uint8Array.indexOf(0);

  if (nullIndex === -1) {
    // ヌル文字が見つからない場合は、元のArrayBufferを返す
    return [arrayBuffer, null];
  }

  // ヌル文字の前後でArrayBufferを分割
  const firstPart = arrayBuffer.slice(0, nullIndex);
  const secondPart = arrayBuffer.slice(nullIndex + 1);

  return [firstPart, secondPart];
}

function arrayBufferToString(arrayBuffer: ArrayBufferLike, encoding = "utf-8") {
  const decoder = new TextDecoder(encoding);
  const utf8Array = new Uint8Array(arrayBuffer);
  return decoder.decode(utf8Array);
}

const createStats = (): LoaderStats => {
  return {
    aborted: false,
    loaded: 0,
    retry: 0,
    total: 0,
    chunkCount: 0,
    bwEstimate: 0,
    loading: { start: performance.now(), first: performance.now(), end: performance.now() },
    parsing: { start: 0, end: 0 },
    buffering: { start: 0, first: 0, end: 0 }
  };
}

const fastLoad = (context: LoaderContext, config: LoaderConfiguration, callbacks: LoaderCallbacks<LoaderContext>, object: Bytes) => {
  setTimeout(() => {
    const objectBuff = object.toUint8Array();
    const stats = createStats();
    const data = context.responseType == "text" ? arrayBufferToString(objectBuff) : objectBuff;
    const len = typeof data == "string" ? data.length : data.byteLength;
    stats.loaded = stats.total = len;
    const response = {
      url: context.url,
      data: data,
      code: 200,
    };
    callbacks.onSuccess(response, stats, context, {});
  }, 1);
}

class HTTPLoader {
  private _fastLoadObjects: { [key: string]: Bytes };

  constructor(fastLoadObjects: { [key: string]: Bytes }) {
    this._fastLoadObjects = fastLoadObjects;
  }

  get loader(): new (config: HlsConfig) => Loader<LoaderContext> {
    const httpLoader = this;
    return class extends Hls.DefaultConfig.loader {
      constructor(config: HlsConfig) {
        super(config);
      }

      load(context: LoaderContext, config: LoaderConfiguration, callbacks: LoaderCallbacks<LoaderContext>) {
        const key = getFilenameFromURL(context.url);
        if (httpLoader._fastLoadObjects[key]) {
          fastLoad(context, config, callbacks, httpLoader._fastLoadObjects[key]);
        } else {
          if ((window as any).DEBUG) {
            context.url += `?t=${uuid.v4()}`;
          }
          super.load(context, config, callbacks);
        }
      };
    }
  }
}

// src/utils/xhr-loader.tsを参考にする
class WebSocketLoader {
  private _socket: WebSocket
  private _objectLoaders: { key: string, loader: Loader<LoaderContext> & { handleSuccess(objectBuff: ArrayBuffer): void, handleCancel(): void } }[];
  private _fastLoadObjects: { [key: string]: Bytes }

  constructor(src: string, fastLoadObjects: { [key: string]: Bytes }) {
    this._objectLoaders = [];
    this._fastLoadObjects = fastLoadObjects;
    this._socket = new WebSocket(src);
    this._socket.onmessage = async (event: MessageEvent<Blob>) => {
      const buff = await blobToArrayBuffer(event.data);
      const [keyBuff, objectBuff] = splitArrayBufferAtNull(buff);
      if (objectBuff) {
        const key = arrayBufferToString(keyBuff);
        console.log(`Fetched: ${key}`); 
        let i = 0;
        for (const objectLoader of this._objectLoaders) {
          if (objectLoader.key == key) {
            objectLoader.loader.handleSuccess(objectBuff);
            this._objectLoaders.slice(i, 1);
          }
          i++;
        }
      }
    };
    this._socket.onclose = async (ev: CloseEvent) => {
      console.log("on close")
      Object.values(this._objectLoaders).forEach(loader => loader.loader.handleCancel());
      this._objectLoaders = [];
    };
  }

  close() {
    this._socket.close();
  };

  get loader(): new (config: HlsConfig) => Loader<LoaderContext> {
    const websocketLoader = this;
    return class {
      private _objectKey: string|null;
      private _callbacks: LoaderCallbacks<LoaderContext>|null;

      public context: LoaderContext|null;
      public stats: LoaderStats;

      constructor() {
        this._objectKey = null;
        this._callbacks = null;
        this.context = null;
        this.stats = createStats();
      }

      handleSuccess(objectBuff: ArrayBuffer) {
        if (!this.context || !this._callbacks) {
          return;
        }
        const data = this.context.responseType == "text" ? arrayBufferToString(objectBuff) : objectBuff;
        const len = typeof data == "string" ? data.length : data.byteLength;

        const response = { url: this.context.url, data, code: 200 };

        this.stats.loading.end = performance.now();
        this.stats.loaded = this.stats.total = len;
        this.stats.bwEstimate = (this.stats.total * 8000) / (this.stats.loading.end - this.stats.loading.first);

        this._callbacks.onSuccess(response, this.stats, this.context, {});
      }

      handleCancel() {
        this._callbacks?.onError(
          { code: 410, text: "Websocket closed" },
          this.context as LoaderContext,
          {},
          this.stats
        );
      }

      destroy() {
        this._objectKey = null;
        this._callbacks = null;
        this.context = null;
        // @ts-ignore
        this.stats = null;
      }

      async abort() {
        await sendMessageWhenConnected(websocketLoader._socket, `cancel:${this._objectKey}`);
        if (this._callbacks?.onAbort) {
          this._callbacks.onAbort(
            this.stats,
            this.context as LoaderContext,
            {}
          );
        }
      }

      async load(context: LoaderContext, config: LoaderConfiguration, callbacks: LoaderCallbacks<LoaderContext>) {
        this.context = context;
        this._callbacks = callbacks;

        const key = getFilenameFromURL(context.url);
        this._objectKey = key;

        if (websocketLoader._fastLoadObjects[key]) {
          fastLoad(context, config, callbacks, websocketLoader._fastLoadObjects[key]);
        } else {
          console.log(`Fetching: ${key}`);
          websocketLoader._objectLoaders.push({ key, loader: this });
          await sendMessageWhenConnected(websocketLoader._socket, `request:${key}`);
        }
      }
    }
  }
};

const createLoaderWapper = (getLoader: () => new (config: HlsConfig) => Loader<LoaderContext>): new (config: HlsConfig) => Loader<LoaderContext> => {
  return function (this: Loader<LoaderContext>, config: HlsConfig) {
    const loaderType = getLoader();
    const loader = new loaderType(config);
    Object.assign(this, loader);
    (this as any).__proto__ = loaderType.prototype;
  } as any;
}

export const WebSocketPlayer: FC<{ wsSrc: string, httpSrc: string, getUseHttp: () => boolean, license: string, fastLoadObjects: { [key: string]: Bytes } }> = (props) => {
  const [webSocketLoader, setWebSocketLoader] = useState<WebSocketLoader|null>(null);

  useEffect(() => {
    if (props.getUseHttp()) {
      setWebSocketLoader(null);
      return;
    }
    const newLoader = new WebSocketLoader(props.wsSrc, props.fastLoadObjects);
    setWebSocketLoader(newLoader);
    return () => {
      newLoader.close();
    };
  }, [props.wsSrc, props.fastLoadObjects, props.getUseHttp()]);

  const SEGMENT_DURATION = 2;

  const onSeek = (seek: number, video: HTMLVideoElement) => {
    if (props.getUseHttp()) {
      return;
    }
    let newSeek = Math.trunc(seek / SEGMENT_DURATION) * SEGMENT_DURATION;
    if (newSeek == seek) {
      return;
    }
    video.currentTime = newSeek;
    console.log(`Seek round: ${seek} => ${newSeek}`);
  };

  const loader = createLoaderWapper(() => {
    return props.getUseHttp() 
      ? (new HTTPLoader(props.fastLoadObjects)).loader
      : (webSocketLoader?.loader || new HTTPLoader(props.fastLoadObjects).loader);
  });

  const hlsConfig: Partial<HlsConfig> = {
    pLoader: loader as new (config: HlsConfig) => Loader<PlaylistLoaderContext>,
    fLoader: loader as new (config: HlsConfig) => Loader<FragmentLoaderContext>,
    // fragLoadingTimeOut: 5000,
    maxMaxBufferLength: props.getUseHttp() ? 600 : 30 // デフォルトで600秒であるため、セグメントが2秒のため300回のリクエストが飛ぶのを防ぐ
  };

  return <VideoPlayer key={props.httpSrc} src={props.httpSrc} license={props.license} onSeek={onSeek} hlsConfig={hlsConfig}/>;
};

export default WebSocketPlayer;
