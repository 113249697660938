import Intercom from '@intercom/messenger-js-sdk';
import { getAuth, onAuthStateChanged, signInWithCustomToken, signInWithEmailLink } from "firebase/auth";
import { createContext, useContext, useEffect, useState } from "react";
import { UserState } from "../ApiTypes";
import { globalConfig, sendEventPageView } from "../api";
import { useAsyncEffect } from "../utils";

export const UserStateContext = createContext(new UserState(undefined));

const removeHistoryQueryParams = () => {
  const urlWithoutParams = window.location.href.split('?')[0];
  window.history.replaceState({}, document.title, urlWithoutParams);
};

function removeQueryParam(url: string, paramName: string) {
  const urlObj = new URL(url);
  const params = urlObj.searchParams;
  params.delete(paramName);
  return urlObj.toString();
}

function hasKeyEndingWithClid(urlParams: URLSearchParams) {
  return Array.from(urlParams.keys()).some(key => key.endsWith('clid'));
}

export const useUserStateContext = () => {
  const onUpdate = () => {
    setUserState(new UserState(getAuth().currentUser, onUpdate));
  };

  const [userState, setUserState] = useState<UserState>(new UserState(undefined, onUpdate));

  useEffect(() => {
    return onAuthStateChanged(getAuth(), async (authUser) => {
      console.log("onAuthStateChanged", authUser);

      if (authUser) {
        console.log("start Intercom");
        Intercom({
          app_id: "lbe7p3ir",
          email: authUser.email || undefined,
          user_id: authUser.uid,
          created_at: authUser.metadata.creationTime ? Date.parse(authUser.metadata.creationTime) : undefined
        });
      }

      setUserState(new UserState(authUser, onUpdate));
    });
  }, []);

  useAsyncEffect(async () => {
    const urlParams = new URLSearchParams(window.location.search);

    const oobCode = urlParams.get("oobCode");
    const loginCustomToken = urlParams.get("loginCustomToken");

    // メールアドレスでログインする
    if (oobCode) {
      try {
        const email = atob(urlParams.get("email") as string);
        console.log("signInWithEmailLink", email);
        await signInWithEmailLink(getAuth(), email, window.location.href);
        localStorage.removeItem("temporarySwap");
        localStorage.removeItem("temporarySwapCanceled");
        removeHistoryQueryParams();
      } catch(error) {
        console.log(error);
        window.location.href = "/invaild-login-link";
      }
    }
    // メールアドレス認証コードでログインする
    else if (loginCustomToken) {
      await signInWithCustomToken(getAuth(), loginCustomToken);
      localStorage.removeItem("temporarySwap");
      localStorage.removeItem("temporarySwapCanceled");
      removeHistoryQueryParams();
    }
    // クリックIDを送信する
    else if (hasKeyEndingWithClid(urlParams)) {
      await sendEventPageView();
      removeHistoryQueryParams();
    }
  }, [window.location.search]);

  useAsyncEffect(async () => {
    const customToken = globalConfig.TEST_CUSTOM_TOKEN;
    if (customToken) {
      try {
        const cred = await signInWithCustomToken(getAuth(), customToken)
        console.log('Loggedin test account', cred.user.uid);
      } catch(error) {
        console.error('Failed to login test account', error);
      }
    };
  }, []);

  return userState;
};

export const useIdToken = () => {
  const userState = useContext(UserStateContext);
  const [idToken, setIdToken] = useState<string|null>(null);

  useAsyncEffect(async () => {
    if (userState.user) {
      setIdToken(await userState.user.getIdToken());
    }
  }, [userState.user])

  return idToken;
};
