import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

interface LanguageOption {
  code: string;
  name: string;
}

const LanguageSelectorContainer = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 2px solid #424242;
`;

const LanguageSelect = styled.select`
  width: 50px;
  padding: 5px 10px;
  font-size: 16px;
  border-radius: 4px;
  border: 1px solid #ccc;
  background-color: #fff;
  appearance: none;
  cursor: pointer;

  &:focus {
    outline: none;
    border-color: #007bff;
    box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.25);
  }
`;

const SelectLanguage: React.FC = () => {
  const languageOptions: LanguageOption[] = [
    { "code": "en", "name": "English" },
    { "code": "de", "name": "Deutsch" },
    { "code": "es", "name": "Español" },
    { "code": "fr", "name": "Français" },
    { "code": "it", "name": "Italiano" },
    { "code": "pt", "name": "Português" },
    { "code": "ru", "name": "Русский" },
    { "code": "ja", "name": "日本語" },
    { "code": "ko", "name": "한국어" },
    { "code": "zh", "name": "中文" },
    { "code": "ar", "name": "العربية" }
  ];

  const { i18n } = useTranslation();
  const [selectedLanguage, setSelectedLanguage] = useState<string>(i18n.language.slice(0, 2));
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);

  const handleLanguageChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const code = event.target.value;
    setSelectedLanguage(code);
    i18n.changeLanguage(code);
    console.log(`Change language to ${code}`);
    window.location.reload();
  };

  const handleDropdownToggle = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  return (
    <LanguageSelectorContainer>
      <LanguageSelect
        id="language-select"
        value={selectedLanguage}
        onChange={handleLanguageChange}
        onFocus={handleDropdownToggle}
        onBlur={handleDropdownToggle}
      >
        {languageOptions.map((option) => (
          <option key={option.code} value={option.code}>
            {selectedLanguage != option.code ? option.name : option.code.toUpperCase()}
          </option>
        ))}
      </LanguageSelect>
    </LanguageSelectorContainer>
  );
};

export default SelectLanguage;