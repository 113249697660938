import { FC, useContext, useState } from 'react';
import styled from 'styled-components';
import { Swap } from '../ApiTypes';
import { getSwaps } from '../api';
import { UserStateContext } from '../contexts/UserStateContext';
import { useAsyncEffect } from '../utils';
import SwapItem from './SwapItem';

const SwapListContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  gap: 20px;
`;

const SwapList: FC<{ watchingSwapId?: string }> = (props) => {
  const [swaps, setSwaps] = useState<Swap[]>([]);
  const userState = useContext(UserStateContext);
  
  useAsyncEffect(async () => {
    setSwaps(await getSwaps());
  }, [userState]);

  return <SwapListContainer>
    {swaps.filter(swap => swap.swapId != props.watchingSwapId).map(swap => <SwapItem key={swap.swapId} swap={swap} />)}
  </SwapListContainer>
};

export default SwapList;
