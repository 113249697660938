import { FC, useState } from 'react';
import styled from 'styled-components';
import { DetectedFace, Face } from '../../ApiTypes';
import { getFaceCroppedSrc, getFaceMeshedSrc } from '../../Storage';
import { getFaces } from '../../api';
import { EmptyMessage, LoadingMessage } from '../../styles/Messages';
import { removeDuplicates, useAsyncEffect } from '../../utils';
import { Trans } from 'react-i18next';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const FaceList = styled.div<{ facecount: number }>`
  display: flex;
  flex-wrap: wrap;
  overflow-y: auto; 
  gap: 10px;
  height: auto;
  width: 100%;

  @media (max-width: 560px) {
    aspect-ratio: ${props => props.facecount >= 9 ? 1 : "auto"};
  }
  @media (min-width: 560px) {
    max-height: 380px;
  }
`;

const FaceItemContainer = styled.div<{ selected: boolean }>`
  background-color: ${props => props.selected ? 'rgb(254, 167, 0)' : '#2b2b2b'};
  border-radius: 10px;
  padding: 10px;
  display: flex;
  gap: 10px;
  // border: 1px solid #424242;
  display: flex;
  flex-direction: column;
  cursor: pointer;

  @media (max-width: 560px) {
    box-sizing: border-box;
    width: calc(100% / 3 - 20px / 3);
  }
`;

const FaceCroppedImg = styled.img`
  border-radius: 50%;
  object-fit: cover; 
  overflow: hidden;
  width: 85px;
  height: 85px;

  @media (max-width: 560px) {
    width: auto;
    height: auto;
  }
`;

const SelectedFaceImg = styled.img`
  border-radius: 10px;
  width: 250px;
  height: 250px;
  object-fit: cover;
  overflow: hidden;
`;

const FaceSelectedContainer = styled.div`
  max-width: 300px;
  display: flex;
  align-items: center;
  flex-direction: column;
  border: 1px solid #424242;
  border-radius: 10px;
  margin-top: 20px;
`;

const FaceSelectedTitle = styled.div`
  font-weight: bold;
  margin: 10px 0;
`;

const FaceTitle = styled.div`
  font-size: 12px;
  width: 178px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
`;

const MeshedFace = styled.img`
  border-radius: 10px;
  width: 250px;
  height: 250px;
  object-fit: cover;
  overflow: hidden;
`;

const FaceItem: FC<{ selected: boolean, face: DetectedFace, onSelect(): void }> = (props) => {
  return <FaceItemContainer selected={props.selected} onClick={e => props.onSelect()}>
    <FaceCroppedImg src={getFaceCroppedSrc(props.face)} />
  </FaceItemContainer>;
};

const SelectFace: FC<{ updationKey: any, face: DetectedFace|null, onSelect(face: DetectedFace|null): void }> = (props) => {
  const [faces, setFaces] = useState<Face[]|null>(null);

  useAsyncEffect(async () => {
    const faces = await getFaces();
    setFaces(removeDuplicates(faces.filter(x => x.hash), x => x.hash));
  }, [props.updationKey]);

  return <Container>
    {faces && <FaceList facecount={faces.length} >
      {faces.map(face => 
          <FaceItem key={face.faceId} 
            face={face}
            onSelect={() => props.onSelect(face)} 
            selected={face.faceId == props.face?.faceId} 
          />)
      }
    </FaceList>}
    {faces && faces.length == 0 && <EmptyMessage>
      <Trans>You have not yet uploaded face photos</Trans>
    </EmptyMessage>}
    {!faces && <LoadingMessage />}
    {props.face  && <>
      <FaceSelectedContainer>
        <FaceSelectedTitle>
          <Trans>Selected face</Trans>
        </FaceSelectedTitle>
        <MeshedFace src={getFaceMeshedSrc(props.face)} />
      </FaceSelectedContainer>
    </>}
  </Container>;
};

export default SelectFace;
