import { FC, useContext } from 'react';
import styled from 'styled-components';
import { DetectedFace, DetectedOriginal, Swap } from '../ApiTypes';
import { getFaceCroppedSrc, getSwapThumbnailUrl } from '../Storage';
import { UserStateContext } from '../contexts/UserStateContext';

const FACE_RATIO = 0.4

const SourceContainer = styled.div`
  position: relative;
`;

const OriginalImg = styled.img<{ width: number }>`
  display: block;
  width: ${props => `${props.width}px`};
  height: ${props => `${props.width / 16 * 9}px`};
  border-radius: ${props => `${props.width * FACE_RATIO / 2}px`};
  position: relative;
  object-fit: cover;
  object-position: center; 

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: radial-gradient(circle at 100% 100%, transparent 0%, rgba(0, 0, 0, 0.5) 100%);
    z-index: 1;
  }

  @media (max-width: 560px) {
    width: 100%;
    height: auto;
    aspect-ratio: 16 / 9;
    border-radius: 10px;
  }
`;

const FaceImg = styled.img<{ width: number }>`
  display: block;
  position: absolute;
  border-radius: 50%;
  width: ${props => `${props.width * FACE_RATIO}px`};
  height: ${props => `${props.width * FACE_RATIO}px`};
  object-fit: cover; 
  overflow: hidden;
  z-index: 2;
  right: 0px;
  bottom: 0px;

  @media (max-width: 560px) {
    width: 33%;
    height: auto;
    aspect-ratio: 1;
  }
`;

const SourceView: FC<{ original: DetectedOriginal, face: DetectedFace, swap?: Swap, width: number }> = (props) => {
  const userState = useContext(UserStateContext);

  return <SourceContainer>   
    {userState.userId && <OriginalImg src={props.swap?.version == 2 ? getSwapThumbnailUrl(userState.userId, props.swap.swapId) : props.original.detected.imageUrl} width={props.width} />}
    {userState.userId && <FaceImg src={getFaceCroppedSrc(props.face)} width={props.width} />}
  </SourceContainer>
};

export default SourceView;
