import { DetectedFace } from "./ApiTypes";

export const BASE_PATH = "https://storages.work";

export const getOriginalThumbnailUrl = (userId: string, originalId: string) => {
  return `${BASE_PATH}/users/${userId}/originals/${originalId}/thumbnail.jpg`;
};

export const getSwapThumbnailUrl = (userId: string, swapId: string) => {
  return `${BASE_PATH}/users/${userId}/swaps/${swapId}/original_thumbnail.jpg`;
};

export const getFaceCroppedSrc = (face: DetectedFace) => {
  return face.croppedFace 
    ? `data:image/jpeg;base64,${face.croppedFace}` 
    : `${BASE_PATH}/users/${face.userId}/faces/${face.faceId}/cropped.jpg`;
};

export const getFaceMeshedSrc = (face: DetectedFace) => {
  return face.meshedFace 
    ? `data:image/jpeg;base64,${face.meshedFace}` 
    : `${BASE_PATH}/users/${face.userId}/faces/${face.faceId}/meshed.jpg`;
};

export const getDownloadUrl = (userId: string, swapId: string) => {
  return `${BASE_PATH}/users/${userId}/swaps/${swapId}.ts`;
}