// Import the functions you need from the SDKs you need
import AsyncLock from "async-lock";
import axios from "axios";
import { getAnalytics } from "firebase/analytics";
import { initializeApp } from "firebase/app";
import { getAuth, onAuthStateChanged, signInWithCustomToken, User } from "firebase/auth";
import { collection, doc, DocumentData, DocumentSnapshot, getDoc, getDocs, getFirestore, onSnapshot, orderBy, query, where } from 'firebase/firestore';
import { DetectedFace, DetectedOriginal, Face, Original, Streaming, Swap, TemporarySwap, UserData } from "./ApiTypes";

//===========================================================================
// Firebase初期化
//===========================================================================
const firebaseConfig = {
  apiKey: "AIzaSyDsN27oMh82vM7849tajQFOJ83YMm0I4PE",
  authDomain: "deepface-390117.firebaseapp.com",
  projectId: "deepface-390117",
  storageBucket: "deepface-390117.appspot.com",
  messagingSenderId: "671899292414",
  appId: "1:671899292414:web:5ea03180adc56613698baf",
  measurementId: "G-P8W5TVHFTZ"
};

export const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

//===========================================================================
// 全体の設定
//===========================================================================
interface GlobalConfig {
  DEBUG?: boolean;
  PORN_MODE?: boolean;
  BACKEND_ENDPOINT: string;
  TEST_CUSTOM_TOKEN?: string;
};

export const globalConfig = (window as any) as GlobalConfig;

export const getPornMode = () => {
  if (window.location.hostname == "video-swap.com") {
    return false;
  }
  if (globalConfig.PORN_MODE !== undefined) {
    return globalConfig.PORN_MODE
  }
  return true;
};

export const getSiteName = () => {
  return getPornMode() ? "PornSwap" : "VideoSwap";
};

//===========================================================================
// サーバー関連
//===========================================================================
export const getSeverEndpoint = () => (window as any).BACKEND_ENDPOINT;

export const getWsSeverEndpoint = () => getSeverEndpoint().replace("http", "ws").replace("https", "wss")

//===========================================================================
// ログイン関連
//===========================================================================

// 現在ログインしているユーザーを取得する
export const getLoginedUser = (): Promise<User|null> => {
  return new Promise((resolve) => {
    const currentUser = getAuth().currentUser;
    if (currentUser) {
      resolve(currentUser);
    } else {
      const unsubscribe = onAuthStateChanged(getAuth(), user => {
        resolve(user);
        unsubscribe();
      });
    };
  });
}

// ログインされるまで待機してユーザーを取得
const getUserWaitForLogin = (): Promise<User> => {
  return new Promise((resolve) => {
    const currentUser = getAuth().currentUser;
    if (currentUser) {
      resolve(currentUser);
    } else {
      const unsubscribe = onAuthStateChanged(getAuth(), user => {
        if (user) {
          resolve(user);
          unsubscribe();
        }
      });
    };
  });
}

const lock = new AsyncLock();

const createUser = async () => {
  await lock.acquire("createUser", async () => {
    await signInWithCustomToken(getAuth(), await createAccount());
    return;
  });
};

const requestBackend = async (user: User|null, name: string, data: FormData | { [key: string]: any }, noAuth?: boolean) => {
  try {
    let headers = {};
    if (user) {
      const idToken = await user.getIdToken();
      headers = { Authorization: idToken };
    }
    const response = await axios.post(`${getSeverEndpoint()}/${name}`, data, { headers });
    return response.data;
  }
  catch (exception) {
    console.log({ name, data, exception });
    throw exception;
  }
}

//===========================================================================
// POST API
//===========================================================================

export const checkIssue = async (swapId: string) => {
  const user = await getLoginedUser();
  const response = await requestBackend(user, "check_issue", {swapId});
  return response.haveIssue;
};

export const sendLoginCode = async (email: string) => {
  const user = await getLoginedUser();
  await requestBackend(user, "send_login_code", {email});
};

export const loginFromCode = async (email: string, loginCode: string) => {
  const user = await getLoginedUser();
  const response = await requestBackend(user, "login_from_code", {email, loginCode});
  return response.token as string;
};

export const signupFromEmail = async (email: string) => {
  const user = await getLoginedUser();
  const response = await requestBackend(user, "signup_from_email", {email});
  return response.token as string | null;
};

export const detectVideo = async (url: string) => {
  const user = await getLoginedUser();
  const response = await requestBackend(user, "detect_video", {url});
  const result: DetectedOriginal = { 
    url,
    detected: {
      imageUrl: response.imageUrl as string, 
      title: response.title as string
    }
  };
  return result;
};

export const detectFace = async (file: Blob) => {
  const user = await getLoginedUser();
  const formData = new FormData();
  formData.append("file", file);
  const response = await requestBackend(user, "detect_face", formData);
  const result: DetectedFace = {
    userId: user?.uid as string,
    faceId: response.faceId as string,
    croppedFace: response.croppedFace as string,
    meshedFace: response.meshedFace as string
  };
  return result;
};

export const processTemporarySwap = async (tmpSwapId: string) => {
  const user = await getLoginedUser();
  if (!user) {
    throw "Not loggedin";
  }
  await requestBackend(user, "process_temporary_swap", { tmpSwapId });
};

export const createSwap = async (originalUrl: string, originalThumbnailUrl: string, faceId: string, durationLimit?: number) => {
  const user = await getLoginedUser();
  if (!user) {
    throw "Not loggedin";
  }
  const response = await requestBackend(user, "create_swap", {
    originalUrl, 
    originalThumbnailUrl,
    faceId,
    successUrl: `${window.location.origin}/swaps/SWAP_ID?generating=1`,
    cancelUrl: `${window.location.origin}/swaps`,
    durationLimit
  });
  return {
    swapId: response.swapId as string, 
    actionUrl: response.actionUrl as string | null
  };
};

export const createSwapNewUser = async (face: DetectedFace, original: DetectedOriginal, durationLimit?: number) => {
  const response = await requestBackend(null, "create_swap_new_user", {
    originalUrl: original.url, 
    originalThumbnailUrl: original.detected.imageUrl,
    originalTitle: original.detected.title,
    croppedFace: face.croppedFace as string,
    meshedFace: face.meshedFace as string,
    baseUrl: window.location.origin,
    durationLimit
  });
  localStorage.setItem("temporarySwap", response.tmpSwapId);
  localStorage.removeItem("temporarySwapCanceled");
  return {
    tmpSwapId: response.tmpSwapId as string, 
    actionUrl: response.actionUrl as string
  };
}

export const checkoutSwap = async (swapId: string) => {
  const user = await getLoginedUser();
  if (!user) {
    throw "Not loggedin";
  }
  const response = await requestBackend(user, "checkout_swap", {
    swapId, 
    successUrl: `${window.location.origin}/swaps/SWAP_ID`,
    cancelUrl: `${window.location.origin}/swaps`
  });
  return { 
    actionUrl: response.actionUrl as string | null
  };
};

export const createAccount = async () => {
  const response = await requestBackend(null, "create_account", {});
  return response.customToken as string;
};

export const deleteAccount = async () => {
  const user = await getLoginedUser();
  if (!user) {
    throw "Not loggedin";
  }
  await requestBackend(user, "delete_account", {});
};

export const getPaymentEmail = async (tmpSwapId: string) => {
  const response = await requestBackend(null, "get_payment_email", { tmpSwapId });
  return response.email as string | null;
};

export const getCustomToken = async () => {
  const user = await getLoginedUser();
  if (!user) {
    throw "Not loggedin";
  }
  const response = await requestBackend(user, "get_custom_token", {});
  return response.customToken as string;
};

export const sendEventPageView = async () => {
  const clarityCookie = document.cookie.replace(/(?:(?:^|.*;\s*)_clck\s*\=\s*([^;]*).*$)|^.*$/, "$1");
  const clarityUserId = decodeURIComponent(clarityCookie).split("|")[0];
  await requestBackend(null, "page_view" + window.location.search + `&clarity=${clarityUserId}`, {});
};

//===========================================================================
// GET API
//===========================================================================

const toFace = (userId: string, doc: DocumentSnapshot<DocumentData, DocumentData>): Face => {
  return { userId, faceId: doc.id, ...doc.data() } as Face
};

export const getFaces = async () => {
  const user = await getLoginedUser();
  if (!user) {
    return [];
  }
  const ref = collection(getFirestore(), "users", user.uid, "faces");
  const querySnapshot = await getDocs(query(ref, orderBy("createdAt", "desc")));
  return querySnapshot.docs.map(doc => toFace(user.uid, doc));
};

const toOriginal = (userId: string, doc: DocumentSnapshot<DocumentData, DocumentData>): Original => {
  const data = doc.data();
  if (!data) {
    throw "DocumentData is undefined"
  }
  return {
    userId,
    originalId: doc.id,
    url: data.url,
    detected: {
      imageUrl: data.detected.imageUrl || data.detected.image_url,
      title: data.detected.title
    }
  };
};

export const getOriginals = async () => {
  const user = await getLoginedUser();
  if (!user) {
    return [];
  }
  const ref = collection(getFirestore(), "users", user.uid, "originals");
  const querySnapshot = await getDocs(query(ref, orderBy("createdAt", "desc")));
  return querySnapshot.docs.map(doc => {
    return toOriginal(user.uid, doc);
  });
};

const toOnlySwap = (userId: string, doc: DocumentSnapshot<DocumentData, DocumentData>) => {
  const data = doc.data() as any;
  if (data.originalId) {
    return { version: 1, userId, swapId: doc.id, ...data };
  }
  return { version: 2, userId, swapId: doc.id, ...data };
};

const getOnlySwaps = async () => {
  const user = await getLoginedUser();
  if (!user) {
    return [];
  }
  const ref = collection(getFirestore(), "users", user.uid, "swaps");
  const querySnapshot = await getDocs(query(ref, orderBy("createdAt", "desc")));
  return querySnapshot.docs.map(doc => toOnlySwap(user.uid, doc));
};

export const getSwaps = async () => {
  const [originals, faces, onlySwaps] = await Promise.all([getOriginals(), getFaces(), getOnlySwaps()]);
  const facesById: { [key: string]: Face } = {};
  const originalsByUrl: { [key: string]: Original } = {};
  const originalsById: { [key: string]: Original } = {};
  originals.forEach(original => {
    originalsByUrl[original.url] = original;
    originalsById[original.originalId] = original;
  });
  faces.forEach(face => {
    facesById[face.faceId] = face;
  });
  return onlySwaps.map(onlySwap => {
    const swap: Swap = {
      original: onlySwap.version == 1 ? originalsById[onlySwap.originalId] : originalsByUrl[onlySwap.originalUrl],
      face: facesById[onlySwap.faceId],
      ...onlySwap
    };
    return swap;
  });
};

export const getSwap = async (swapId: string): Promise<Swap> => {
  const user = await getLoginedUser();
  if (!user) {
    throw "Not loggined"
  }
  const swapDoc = await getDoc(doc(getFirestore(), "users", user.uid, "swaps", swapId));
  const swap = toOnlySwap(user.uid, swapDoc);
  const [originalDoc, faceDoc] = await Promise.all([
    (async () => {
      if (swap.version == 1) {
        return await getDoc(doc(getFirestore(), "users", user.uid, "originals", swap.originalId));
      } else {
        const originalDocs = await getDocs(query(collection(getFirestore(), "users", user.uid, "originals"), where("url", "==", swap.originalUrl)));
        return originalDocs.docs[0];
      }
    })(),
    getDoc(doc(getFirestore(), "users", user.uid, "faces", swap.faceId))
  ]);

  return {
    ...swap,
    face: toFace(user.uid, faceDoc),
    original: toOriginal(user.uid, originalDoc)
  };
};

export const getUserData = async (): Promise<UserData> => {
  const user = await getLoginedUser();
  if (!user) {
    throw "Not loggined"
  }
  const userDoc = await getDoc(doc(getFirestore(), "users", user.uid));
  return userDoc.data() as UserData;
};

export const subscribeStreaming = async (streamingId: string, onChange: (streaming: Streaming) => void) => {
  const user = await getLoginedUser();
  if (!user) {
    throw "Not loggined"
  }
  const ref = doc(getFirestore(), "users", user.uid, "streamings", streamingId);
  return onSnapshot(ref, snapshot => {
    const data = snapshot.data();
    if (data) {
      onChange({ userId: user.uid, streamingId: snapshot.id, ...data } as Streaming);
    }
  });
};

export const getTemporarySwap = async (tmpSwapId: string) => {
  const tmpSwap = await getDoc(doc(getFirestore(), "temporary_swaps", tmpSwapId));
  return tmpSwap.exists() ? tmpSwap.data() as TemporarySwap : null;
};
