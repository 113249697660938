import { getAuth, sendSignInLinkToEmail, signInWithCustomToken } from "firebase/auth";
import { FC, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { loginFromCode, sendLoginCode, signupFromEmail } from "../api";
import { UserStateContext } from '../contexts/UserStateContext';
import { SignupButton, SignupTextInput } from '../styles/Inputs';
import { MiniCircleLoading } from '../styles/Loadings';
import { ErrorMessage } from '../styles/Messages';
import { Trans } from "react-i18next";

const Title = styled.div`
  text-align: center;
  color: white;
  font-weight: 700;
  font-size: 22px;
  margin-bottom: 15px;
`;

const Highlight = styled.span`
  color: rgb(254, 167, 0);
`;

function getLoginUrl(loginUrl: string|undefined, email: string) {
  const baseUrl = loginUrl || `${window.location.origin}`;
  return `${baseUrl}?email=${btoa(email)}`;
}

const LoginForm: FC<{ email?: string, loginUrl?: string }> = (props) => {
  const [email, setEmail] = useState(props.email || '');
  const [hasSentEmail, setHasSentEmail] = useState(false);
  const [error, setError] = useState<string|null>(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async () => {
    setError(null);
    setLoading(true);
    try {
      const token = await signupFromEmail(email);
      if (token) {
        await signInWithCustomToken(getAuth(), token);
        if (props.loginUrl) {
          window.location.href = props.loginUrl;
        } else {
          navigate("/");
        }
      } else {
        await sendSignInLinkToEmail(getAuth(), email, {
          url: getLoginUrl(props.loginUrl, email),
          handleCodeInApp: true
        });
        setHasSentEmail(true);
      }
    } catch (error) {
      setError("Error");
      setLoading(false);
    }
  };

  return <>
    <Title>
      {!props.email && <Trans>Sign up or login</Trans>}
      {props.email && <Trans>Sign up or login to save your <Highlight>videos</Highlight></Trans>}
    </Title>

    {!hasSentEmail && <>
      <Trans>Your email to link the account</Trans>
      <SignupTextInput
        id='email'
        type='email'
        placeholder='name@domain.com'
        value={email}
        onChange={e => setEmail(e.target.value)} 
      />
    </>}
    {error && <ErrorMessage>{error}</ErrorMessage>}
    {hasSentEmail && <Trans>A login link has been sent to your email address. Please check your email.</Trans>}
    {!hasSentEmail && <SignupButton id="to-login" disabled={loading || !email} onClick={handleSubmit}>
        {!loading && <Trans>Sign up or login</Trans>}
        {loading && <MiniCircleLoading />}
        {loading && <Trans>Loading...</Trans>}
      </SignupButton>}
  </>;
};

function isValidLoginCodeFormat(str: string) {
  const pattern = /^[a-z]{4}-[a-z]{5}-[a-z]{4}-[a-z]{3}$/;
  return pattern.test(str);
}

const BackButton = styled.span`
  cursor: pointer;
  text-decoration: underline;
  margin-top: 10px;
  font-size: 16px;
  color: gray;
`;

const LoginForm2: FC<{ email?: string, loginUrl?: string }> = (props) => {
  const [email, setEmail] = useState(props.email || '');
  const [hasSentEmail, setHasSentEmail] = useState(false);
  const [error, setError] = useState<string|null>(null);
  const [loading, setLoading] = useState(false);
  const [loginCode, setLoginCode] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async () => {
    setError(null);
    setLoading(true);
    try {
      const token = await signupFromEmail(email);
      if (token) {
        await signInWithCustomToken(getAuth(), token);
        if (props.loginUrl) {
          window.location.href = props.loginUrl;
        } else {
          navigate("/");
        }
      } else {
        await sendLoginCode(email);
        setHasSentEmail(true);
      }
    } catch (error) {
      setError("Error");
    }
    setLoading(false);
  };

  const handleLogin = async () => {
    setError(null);
    setLoading(true);
    try {
      const token = await loginFromCode(email, loginCode);
      await signInWithCustomToken(getAuth(), token);
      if (props.loginUrl) {
        window.location.href = props.loginUrl;
      } else {
        navigate("/");
      }
    } catch (error) {
      setError("Wrong login code");
      setLoading(false);
    }
  };

  const handleUseAnotherEmail = () => {
    setHasSentEmail(false);
  };

  return <>
    <Title>
      {!props.email && <Trans>Sign up or login</Trans>}
      {props.email && <Trans>Sign up or login to save your <Highlight>videos</Highlight></Trans>}
    </Title>

    {!hasSentEmail && <>
      <Trans>Your email to link the account</Trans>
      <SignupTextInput
        id='email'
        type='email'
        placeholder='name@domain.com'
        value={email}
        onChange={e => setEmail(e.target.value)} 
      />
    </>}
    {hasSentEmail && <Trans>Please enter the login code that was sent to your email. The login code is valid for only 10 minutes.</Trans>}
    {hasSentEmail && <SignupTextInput 
      id='login-code'
      type='text'
      placeholder='xxxx-xxxxx-xxxx-xxx'
      value={loginCode}
      onChange={e => setLoginCode(e.target.value)} 
    />}
    {error && <ErrorMessage>{error}</ErrorMessage>}
    {hasSentEmail && <SignupButton id="to-login" disabled={!isValidLoginCodeFormat(loginCode) || loading} onClick={handleLogin}>
        {!loading && <Trans>Submit login code</Trans>}
        {loading && <MiniCircleLoading />}
        {loading && <Trans>Loading...</Trans>}
    </ SignupButton>}
    {hasSentEmail && <BackButton onClick={handleUseAnotherEmail}>{"< Use another email"}</BackButton>
    }
    {!hasSentEmail && <SignupButton id="to-login" disabled={loading || !email} onClick={handleSubmit}>
        {!loading && <Trans>Sign up or login</Trans>}
        {loading && <MiniCircleLoading />}
        {loading && <Trans>Loading...</Trans>}
      </SignupButton>}
  </>;
};

const Center = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
`;

const Container = styled.div`
  flex-direction: column;
  border-radius: 10px;
  // border: 1px solid #424242;
  background-color: #2b2b2b;
  color: white;
  padding: 30px;
  width: 500px;
  margin: 25px 20px 200px 20px;
  display: flex;
  font-weight: 500;
`;

const LoginPage: FC<{ email?: string, loginUrl?: string }> = (props) => {
  const navigate = useNavigate();
  const userState = useContext(UserStateContext);

  useEffect(() => {
    if (props.loginUrl) {
      return;
    }
    if (userState.isSigned) {
      navigate("/account");
    }
  }, [userState, props.loginUrl]);

  return <Center>
    <Container>
      <LoginForm email={props.email} loginUrl={props.loginUrl} />
    </Container>
  </Center>;
};

export default LoginPage;
