import { FC } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const Center = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
`;

const Container = styled.div`
  flex-direction: column;
  color: white;
  width: 500px;
  margin: 25px 20px 20px 20px;
  display: flex;
  font-weight: 500;
  color: #D1D1D1;
`;

const TopContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const Video = styled.video`
  width: 100%;
  border-radius: 10px;
  border: 1px solid #424242;
`;

const FacesVideo = styled.video`
  width: 100%;
  border-radius: 10px;
  border: 1px solid #424242;
  margin-top: 15px;
`;

const TopText = styled.div`
  position: absolute;
  z-index: 2;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 100px;
`;

const Logo = styled.div`
  font-weight: bold;
  color: #fea700;
  text-shadow: 0 0 15px rgba(0, 0, 0, 1);
  text-align: center;
`;

const TopLogo = styled.div`
  font-size: 45px;

  @media (max-width: 560px) {
    font-size: 30px;
  }
`;

const MiniLogo = styled.div`
  font-size: 35px;

  @media (max-width: 560px) {
    font-size: 20px;
  }
`;

const ConceptText = styled.div`
  color: black;
  text-shadow: 0 0 15px white;
  font-size: 20px;
  font-weight: bold;
  margin-top: 10px;

  @media (max-width: 560px) {
    font-size: 15px;
  }
`;

const CreateButton = styled.span`
  margin-top: 10px;
  border-radius: 5px;
  height: 44px;

  background-color: rgb(254, 167, 0);
  color: black;
  padding: 10px;

  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  font-weight: bold;
  color: black;

  &:hover {
    background-color: #f8be53;
  }
`;

const PornSiteIconList = styled.div`
  margin-top: 10px;
  display: flex;
  align-items: center;
`;

const PornSiteIconListCenter = styled.div`
  margin-top: 10px;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
`;

const PornSiteIcon = styled.img`
  height: ${x=> x.height};
`;

const StepImg = styled.img`
  width: calc(100% - 20px);
  margin: 10px;
`;

const StepImgContainer = styled.div`
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #1d1d1d;
  border-radius: 10px;
  border: 1px solid gray;
`;

const StepB = styled.span`
  font-weight: bold;
  color: #fea700;
`;

const StepSubtitle = styled.span`
  font-weight: bold;
  font-size: 15px;
  color: #C0C0C0;
`;

const StepTitle = styled.legend`
  font-weight: 700;
  font-size: 18px;
`;

const StepContainer = styled.fieldset`
  border-radius: 10px;
  background-color: #2b2b2b;
  border: 1px solid #424242;
  padding: 20px;
  margin-top: 20px;
`;

const SectionTitle = styled.div`
  margin-top: 20px;
  font-size: 30px;
  font-weight: bold;
  width: 100%;
  text-align: center;
  color: #fea700;

  @media (max-width: 560px) {
    font-size: 20px;
  }
`;

const SectionText = styled.div`
  font-size: 18px;
  width: 100%;
  margin-top: 10px;

  @media (max-width: 560px) {
    font-size: 16px;
  }
`;

const TweetImgContainer = styled.div` 
  margin-top: 20px;
  border-radius: 15px;
  display: flex;
  padding: 5px;
  padding-top: 15px;
  background-color: white;
  position: relative;
`;

const TweetCatch = styled.span`
  position: absolute;
  font-size: 20px;
  z-index: 100;
  color: black;
  right: 20px;
  font-weight: bold;
`;

const TweetImg = styled.img`
  width: 100%;
`;

const TweetVideo = styled.video<{ width: string, top: string, left: string }>`
  position: absolute;
  z-index: 50;
  width: 100%;
  border-radius: 10px;
  width: ${props => props.width};
  top: ${props => props.top};
  left: ${props => props.left};
`;

const ExTitle = styled.div`
  color: #FFCC00;
  font-size: 25px;
  font-weight: bold;
  width: 100%;
  text-align: center;
  margin-top: 15px;

  @media (max-width: 560px) {
    font-size: 20px;
  }
`;

const ExText = styled.div`
  font-size: 16px;
  width: 100%;
  margin-top: 10px;

  @media (max-width: 560px) {
    font-size: 14px;
  }
`;

const ExVideo = styled.video`
  width: 100%;
  border-radius: 10px;
  border: 1px solid #424242;
  margin-bottom: 15px;
  margin-top: 10px;
`;

const PriceImg = styled.img`
  width: 100%;
  border-radius: 15px;
  border: 1px solid #424242;
  margin-top: 10px;
`;

const LinkButtonContainer = styled.div`
  margin-top: 20px;
`;

const LogoContainer = styled.div`
  background-color: rgba(0, 0, 0, 0.3);
  padding: 15px;
  border-radius: 15px;
  border: 1px solid #fea700;

  @media (max-width: 560px) {
    padding: 10px;
  }
`;

const PriceText = styled.div`
  width: 100%;
  text-align: center;
  font-size: 30px;
  margin-bottom: 10px;
`;

const Emphasis = styled.span`
  font-weight: bold;
  color: white;
`;

const AwardContainer = styled.div`
  width: 100%;
  text-align: center;
  margin-top: 10px;
  display: flex;
`;

const AwardImg = styled.img`
  max-width: 150px;
`;

const AwardText = styled.div`
  text-align: left;
  display: flex;
  align-items: center;
`;

const LinkButton: FC<{}> = (props) => {
  return <LinkButtonContainer>
    <Link to="/create">
      <CreateButton>
        Let's face swap porn in 10 seconds!
      </CreateButton>
    </Link>
  </LinkButtonContainer>;
};
/*


      <dl>
        <dt>Seller Information:</dt>
        <dd>
          <dl>
            <dt>Company Name: Arete LLC.</dt>
            <dd></dd>
          </dl>
          <dl>
            <dt>Representative: Matsumoto Ryui</dt>
            <dd></dd>
          </dl>
          <dl>
            <dt>Address: 5-11-4 Shiba, Minato-ku, Tokyo, Japan</dt>
            <dd></dd>
          </dl>
          <dl>
            <dt>Email Address: info@adventurespark.site</dt>
            <dd></dd>
          </dl>
        </dd>
      </dl>
      

*/
const SCTLSPage: FC<{}> = (props) => {
  return <><Center>
    <Container>
      <SectionTitle>
        Specified Commercial Transactions Law Statement
      </SectionTitle>
      <dl>
        <dt>Product/Service Details:</dt>
        <dd>This service uses generative AI technology to create custom videos based on user input and specifications. Users can provide prompts, select styles and formats, and receive AI-generated video content.</dd>
      </dl>
      <dl>
        <dt>Payment Methods:</dt>
        <dd>We accept payments via credit card and bank transfer. All transactions are securely processed.</dd>
      </dl>
      <dl>
        <dt>Pricing:</dt>
        <dd>$9.99 per a video generation</dd>
      </dl>
      <dl>
        <dt>Delivery Timing:</dt>
        <dd>Videos are typically generated and delivered within a hour after purchase and submission of required inputs, though exact timing may vary based on specifications and queue.</dd>
      </dl>
      <dl>
        <dt>Refund Policy:</dt>
        <dd>Due to the custom nature of AI-generated videos, we generally do not offer refunds. However, if the delivered video fails to meet reasonable interpretations of user specifications, we will regenerate the video up to 3 times. If still unsatisfactory, we will review refund requests on a case-by-case basis.</dd>
      </dl>
      <dl>
        <dt>Prohibited Uses:</dt>
        <dd>Users may not input prompts or request videos that involve illegal, abusive, explicit or copyrighted content. We reserve the right to reject any requests deemed inappropriate.</dd>
      </dl>
      <dl>
        <dt>Intellectual Property:</dt>
        <dd>
          Users maintain rights to the unique video content they generate using our AI service. We maintains ownership of the underlying AI models and technology.
          Contact us at Intercom with any questions or concerns. Thank you for using our generative AI video service.
        </dd>
      </dl>
    </Container>
  </Center>
  </>
};

export default SCTLSPage;
