import { FC, useContext, useState } from 'react';
import styled from 'styled-components';
import { DetectedFace, DetectedOriginal } from '../../ApiTypes';
import { UserStateContext } from '../../contexts/UserStateContext';
import { Visibility } from '../../styles/Containers';
import { InspireMessage } from '../../styles/Messages';
import SlideTab from '../SlideTab';
import CreateFace from './CreateFace';
import SelectFace from './SelectFace';
import { Trans } from 'react-i18next';

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const SetFace: FC<{ initialFace?: DetectedFace, onChange(face: DetectedFace|null): void }> = (props) => {
  const [mode, setMode] = useState<number>(0);
  const [newFace, setNewFace] = useState<DetectedFace|null>(props.initialFace || null);
  const [historyFace, setHistoryFace] = useState<DetectedFace|null>(null);
  const userState = useContext(UserStateContext);

  const changeMode = (mode: number) => {
    setMode(mode);
    props.onChange(mode == 0 ? newFace : historyFace);
  };

  const changeOnNew = (face: DetectedFace|null) => {
    setNewFace(face)
    props.onChange(face);
  };

  const changeOnHistory = (face: DetectedFace|null) => {
    setHistoryFace(face);
    props.onChange(face);
  };

  return <Container>
    <InspireMessage>
      <Trans>Choose a clear photo of your loved one's face😎</Trans><br />
      <Trans>Your uploaded photos are private and secure🔒</Trans>
    </InspireMessage>
    {userState.isSigned && <SlideTab tabs={["Upload", "History"]} index={mode} onChange={mode => changeMode(mode)} />}
    <Visibility visible={mode == 0}><CreateFace face={newFace} onCreate={id => changeOnNew(id)} /></Visibility> 
    {userState.isSigned && <Visibility visible={mode == 1}><SelectFace face={historyFace} onSelect={id => changeOnHistory(id)} updationKey={newFace} /></Visibility>}
  </Container>;
};

export default SetFace;
