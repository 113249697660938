import { FC, useEffect, useRef, useState } from "react";

import Hls, { HlsConfig } from "hls.js";
import TubyPlayer from "./TubyPlayer";

export const VideoPlayer: FC<{ src?: string, license?: string, hlsConfig?: Partial<HlsConfig>, onSeek?(currentTime: number, player: HTMLVideoElement): void }> = (props) => {
  const playerRef = useRef<HTMLVideoElement>(null);
  const [hls, setHls] = useState<Hls|null>(null);

  useEffect(() => {
    if (!playerRef?.current) {
      return
    }

    playerRef.current.addEventListener("seeking", (event) => {
      if (props.onSeek && playerRef?.current) {
        props.onSeek(playerRef.current.currentTime, playerRef.current);
      }
    });
  }, [playerRef?.current]);

  useEffect(() => {
    if (!playerRef?.current) {
      return
    }
    if (!props.src) {
      return;
    }

    const hlsConfig: Partial<HlsConfig> = {
      debug: /*(window as any).DEBUG ||*/ true,
      emeEnabled: true,
      drmSystems: {
        'com.widevine.alpha': {
          licenseUrl: "https://34331b7f.drm-widevine-licensing.axprod.net/AcquireLicense"
        },
        'com.microsoft.playready': {
          licenseUrl: "https://34331b7f.drm-playready-licensing.axprod.net/AcquireLicense"
        },
        'com.apple.fps': {
          licenseUrl: "https://34331b7f.drm-fairplay-licensing.axprod.net/AcquireLicense",
          serverCertificateUrl: `/fairplay.cer`
        }
      },
      licenseXhrSetup: function (xhr, url, keyContext, licenseChallenge) {
        if (props.license) {
          xhr.setRequestHeader('X-AxDRM-Message', props.license);
        }
      },
    };
  
    if (props.hlsConfig) {
      Object.assign(hlsConfig, props.hlsConfig);
    }

    const hls = new Hls(hlsConfig);
    setHls(hls);
    hls.attachMedia(playerRef.current);
    hls.loadSource(props.src);

    return () => {
      hls.destroy();
    };
  }, [props.src, playerRef?.current]);

  return <TubyPlayer disable={props.src == null} playerRef={playerRef} />
};

export default VideoPlayer;
