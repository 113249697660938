import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

const STORAGE_KEY = 'i18next_lang';

const initI18n = i18n
  .use(LanguageDetector) // ユーザーの言語設定を検知するために必要
  .use(initReactI18next)
  .init({
    locales: ['en', 'fr', 'it', 'de', 'ja'],
    fallbackLng: 'es', // デフォルトの言語を設定
    returnEmptyString: false, // 空文字での定義を許可に
    detection: {
      // lookupQuerystring: '',
      lookupCookie: STORAGE_KEY,
      lookupLocalStorage: STORAGE_KEY,
      lookupSessionStorage: STORAGE_KEY,
    },
    interpolation: {
      // 翻訳された文字列内のHTMLやReactコンポーネントをエスケープすることを無効に
      escapeValue: false
    },
    react: {
      // 指定したHTMLノードを翻訳時にそのまま保持して表示するための設定
      transKeepBasicHtmlNodesFor: ['br', 'strong', 'i', 'span'],
      useSuspense: true
    },
    resources: {
      ar: { translation: require('./locales/auto/ar/latest.json') },
      de: { translation: require('./locales/auto/de/latest.json') },
      en: { translation: require('./locales/auto/en/latest.json') },
      es: { translation: require('./locales/auto/es/latest.json') },
      fr: { translation: require('./locales/auto/fr/latest.json') },
      it: { translation: require('./locales/auto/it/latest.json') },
      ja: { translation: require('./locales/auto/ja/latest.json') },
      ko: { translation: require('./locales/auto/ko/latest.json') },
      pt: { translation: require('./locales/auto/pt/latest.json') },
      ru: { translation: require('./locales/auto/ru/latest.json') },
      zh: { translation: require('./locales/auto/zh/latest.json') }
    }
  });

export default initI18n;