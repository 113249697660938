import styled from 'styled-components';

export const TextInput = styled.input`
  box-sizing: border-box;
  width: 100%;
  font-size: 16px;
  border-radius: 5px;
  background-color: #2b2b2b;
  color: white;
  padding: 10px;
  outline: none;
  border: none;
  margin: 10px 0px;
  border: 1px solid #424242;
  flex: 1;

  &:focus {
    border: 1px solid #606060;
  }
`;

export const SignupTextInput = styled.input`
  font-size: 16px;
  border-radius: 5px;
  background-color: #eeeeee;
  color: #111827;
  padding: 12px;
  outline: none;
  border: none;
  margin: 10px 0px;
  border: 1px solid #424242;
  flex: 1;

  &::placeholder {
    color: #c4c4c4;
  }
`;

export const SignupButton = styled.span<{ disabled: boolean }>`
  margin-top: 20px;
  border-radius: 5px;
  height: 44px;
  pointer-events: ${props => props.disabled ? 'none' : 'auto'};

  cursor: ${props => props.disabled ? 'default' : 'pointer'};
  background-color: ${props => props.disabled ? '#926613' : 'rgb(254, 167, 0)'};
  color: black;

  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: ${props => props.disabled ? '#926613' : '#f8be53'};
  }
`;
