import { PaymentRequestButtonElement, useStripe } from '@stripe/react-stripe-js';
import { PaymentRequest, loadStripe } from '@stripe/stripe-js';
import { FC, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { DetectedFace, DetectedOriginal, UserData } from '../ApiTypes';
import { createSwap, createSwapNewUser, getLoginedUser, getPornMode, globalConfig } from '../api';
import { useUserStateContext } from '../contexts/UserStateContext';
import { MiniCircleLoading } from '../styles/Loadings';
import { useAsyncEffect } from '../utils';
import SourceView from './SourceView';
import { Trans } from 'react-i18next';

const stripeKey = globalConfig.DEBUG ? "pk_test_51NCnySHLXgK48oO1tb92Lxa59l2bEw3WoUp4BI52qH5gcXNpFZ9v4GnHHgRra52TIpjp34ONzINlm7sjNroBEMaF00OKlYxDeC" : "pk_live_51NCnySHLXgK48oO1iC0DPL4su8UnObOudOygFRdbyYTp54Kitt1qc9byAPjbo8DK3DtDJi8aKOREo5im1wZt6pLb00ZEWwJ31b";
const stripePromise = loadStripe(stripeKey);

const GenerateButtonContainer = styled.div`
  position: relative;
  border-radius: 30px;
  width: 100%;
  height: 60px;
  background-color: #2b2b2b;

  font-size: 16px;
  font-weight: bold;
`;

// https://cubic-bezier.com/#.22,.55,.76,.41
const Button = styled.span<{ disabled: boolean }>`
  position: absolute;
  left: 50%;
  width: 50%;
  border-radius: 30px;
  height: 60px;
  pointer-events: ${props => props.disabled ? 'none' : 'auto'};

  cursor: ${props => props.disabled ? 'default' : 'pointer'};
  // background-color: ${props => props.disabled ? '#926613' : 'rgb(254, 167, 0)'};
  color: black;

  display: flex;
  align-items: center;
  justify-content: center;

  background: linear-gradient(90deg, #ff5f02, #ffa702, #ff5f02, #ffa702);
  background-size: 300% 100%;
  animation: gradient 1.5s cubic-bezier(.22,.55,.76,.41) infinite;

  @keyframes gradient {
    0% {
      background-position: 300% 0%;
    }
    100% {
      background-position: 400% 0%;
    }
  }
`;

const GenerationText = styled.span`
  position: absolute;
  left: 0px;
  width: 50%;
  height: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const CreditCost = styled.div`
`;

const CreditCostNumber = styled.span`
  color: rgb(254, 167, 0);
`;

const MobilePaymentButton = () => {
  const stripe = useStripe();
  const [paymentRequest, setPaymentRequest] = useState<PaymentRequest|null>(null);

  useEffect(() => {
    if (stripe) {
      const paymentRequest = stripe.paymentRequest({
        country: 'US', 
        currency: 'usd',
        total: {
          label: 'A video generation',
          amount: 999,
        },
        requestPayerName: true,
        requestPayerEmail: true,
      });

      paymentRequest.on('paymentmethod', async (event) => {
            // 決済ロジック with event.paymentMethod.id 
            // if(payCompleted) {
            //     event.complete('success') // 必須
            // } else {
            //     event.complete('fail') // 必須
            // }
      });

      // Check the availability of the Payment Request API.
      paymentRequest.canMakePayment().then(result => {
        console.log("paymentRequest", result);
        if (result) {
          setPaymentRequest(paymentRequest);
        }
      });
    }
  }, [stripe]);

  if (paymentRequest) {
    return <PaymentRequestButtonElement options={{paymentRequest}} />
  }

  return <></>;
}

const GenerateButton: FC<{ loading: boolean, onGenerate(): void }> = (props) => {

  const userState = useUserStateContext();
  const [userData, setUserData] = useState<UserData|null>(null);

  useAsyncEffect(async () => {
    setUserData(await userState.getUserData());
  }, [userState]);

  const generate = () => {
    localStorage.removeItem("temporarySwap");
    props.onGenerate();
  };
  /*
      <Elements stripe={stripePromise}>
        <MobilePaymentButton />
      </Elements>
  */
  return <GenerateButtonContainer>
      <GenerationText>
        <CreditCost>
          {!userData?.credit && <><CreditCostNumber>$9.99</CreditCostNumber><br/><Trans>one-time payment</Trans></>}
          {userData?.credit && <Trans>Consume a credit</Trans>}
        </CreditCost>
      </GenerationText>
      <Button id="to-create-swap" disabled={props.loading} onClick={e => generate()}>
        {!props.loading && (userData?.credit ? <Trans>Generate (Free)</Trans> : <Trans>Buy & Generate</Trans>)}
        {props.loading && <MiniCircleLoading />}
        {props.loading && <Trans>Loading...</Trans>}
      </Button>
  </GenerateButtonContainer>
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
`;

const ConfirmContainer = styled.div`
  display: flex;
  gap: 20px;

  @media (max-width: 560px) {
    flex-direction: column;
  }
`;

const SourceViewContainer = styled.div`
  @media (max-width: 560px) {
    display: flex;
    justify-content: center;
  }
`;

const BannerContainer = styled.div`
  border-radius: 30px;
  overflow: hidden;
  background: #2b2b2b;
  width: 100%;

  @media (min-width: 560px) {
    max-width: 400px;
  }
`;

const BannerText = styled.div`
  text-align: center;
  font-size: 26px;
  font-weight: bold;
  background: linear-gradient(to right, #ffa702, #ff5f02);
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  padding: 5px 0px;
`;

const Video = styled.video`
  width: 100%;
  margin-bottom: -5px;
`;

const Gif = styled.img`
  width: 100%;
  margin-bottom: -5px;
`;


const GenerateSwap: FC<{ face: DetectedFace, original: DetectedOriginal }> = (props) => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const duration = searchParams.get("duration");
  const durationLimit = duration ? parseFloat(duration) : undefined;

  useEffect(() => {
    setLoading(false);
  }, [props.original, props.face])

  const generateSwap = async () => {
    setLoading(true);
    const user = await getLoginedUser();
    if (user) {
      const result = await createSwap(props.original.url, props.original.detected.imageUrl, props.face.faceId as string, durationLimit);
      if (result.actionUrl){
        window.location.href = result.actionUrl;
      } else {
        navigate(`/swaps/${result.swapId}?generating=1`);
      }
    } else {
      const result = await createSwapNewUser(props.face, props.original, durationLimit);
      window.location.href = result.actionUrl;
    }
  };

  const isPorn = props.original.url.includes("pornhub") || props.original.url.includes("xvideos");

  return <Container>
    <ConfirmContainer>
      <SourceViewContainer>
        <SourceView original={props.original} face={props.face} width={300} />
      </SourceViewContainer>
    </ConfirmContainer>
    {getPornMode() && <BannerContainer>
      <BannerText><Trans>Let's stream the video!</Trans></BannerText>
      <Gif src="/lp/card/banner.gif"/>
    </BannerContainer>}
    <GenerateButton loading={loading} onGenerate={() => generateSwap()} />
  </Container>;
};

export default GenerateSwap;
