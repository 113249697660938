import { FC, useCallback, useContext, useRef, useState } from "react";
import styled from "styled-components";
import { DetectedFace } from "../../ApiTypes";
import { getFaceMeshedSrc } from "../../Storage";
import { detectFace } from "../../api";
import { UserStateContext } from "../../contexts/UserStateContext";
import { CircleLoading } from "../../styles/Loadings";
import { FailureMessage, SuccessfulMessage } from "../../styles/Messages";
import { isMobile } from "../../utils";
import { Trans } from "react-i18next";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const FaceUploadContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 200px;
  width: 100%;
  margin-bottom: 10px;
`;

const FaceDropZone = styled.div`
  flex: 1;
  text-align: center;
  border: 1px dashed #424242;
  border-radius: 20px;
  padding: 10px;
  height: 10px;
  cursor: pointer;

  &:hover {
    background-color: #353535;
  }
`;

const FaceDetectedContainer = styled.div`
  max-width: 300px;
  display: flex;
  align-items: center;
  flex-direction: column;
  border: 1px solid #424242;
  border-radius: 10px;
`;

const FaceDetectedTitle = styled.div`
  font-weight: bold;
  margin: 10px 0;
`;

const FaceCroppedImg = styled.img`
  border-radius: 10px;
  width: 250px;
  height: 250px;
  object-fit: cover;
  overflow: hidden;
`;

enum Status {
  FILE_NO_SELECT,
  FILE_INVAILD,
  FILE_SELECTED,
  FACE_DETECTING,
  FACE_DETECTED,
  FACE_FAILED_DETECTED
};

const CreateFace: FC<{ face: DetectedFace|null, onCreate(face: DetectedFace|null): void }> = props => {
  const [status, setStatus] = useState<Status>(props.face ? Status.FACE_DETECTED : Status.FILE_NO_SELECT);
  const [face, setFace] = useState<DetectedFace|null>(props.face);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const userState = useContext(UserStateContext);

  const setFile = async (file: Blob) => {
    if (!file) {
      setStatus(Status.FILE_NO_SELECT);
      return;
    }
    if (!file.type.startsWith("image/")) {
      setStatus(Status.FILE_INVAILD);
      return;
    }

    setStatus(Status.FACE_DETECTING);
    try {
      const face = await detectFace(file);
      setStatus(Status.FACE_DETECTED);
      setFace(face);
      props.onCreate(face);
    } catch(error) {
      console.log(error);
      setStatus(Status.FACE_FAILED_DETECTED);
    }
  };

  const onFileDrop = useCallback(async (event: any) => {
    event.preventDefault();
    const droppedFile = event.dataTransfer.files[0];
    await setFile(droppedFile);
  }, []);

  const onFileChange = useCallback(async (event: any) => {
    const selectedFile = event.target.files[0];
    await setFile(selectedFile);
  }, []);

  const onDragOver = useCallback((event: any) => {
    event.preventDefault();
  }, []);

  const openFileDialog = () => {
    fileInputRef.current?.click();
  };

  return <Container>
    <FaceUploadContainer>
      <FaceDropZone onDrop={onFileDrop} onDragOver={onDragOver} onClick={openFileDialog}>
        {!isMobile() && <Trans>Click or drag and drop here to upload</Trans>}
        {isMobile() && <Trans>Tap and upload</Trans>}
      </FaceDropZone>
      {status == Status.FILE_INVAILD && <FailureMessage><Trans>Please select an image file.</Trans></FailureMessage>}
      {status == Status.FACE_FAILED_DETECTED && <FailureMessage><Trans>Face could not be detected.</Trans></FailureMessage>}
      <input id="to-uplaod-face" ref={fileInputRef} type="file" onChange={onFileChange} style={{ display: 'none' }} />
    </FaceUploadContainer>
    {status == Status.FACE_DETECTING && <CircleLoading />}
    {status == Status.FACE_DETECTED && <>
      <SuccessfulMessage><Trans>The world's highest level AI has successfully analyzed the face.</Trans></SuccessfulMessage>
      <FaceDetectedContainer>
        <FaceDetectedTitle>
          <Trans>Analyzed face</Trans>
        </FaceDetectedTitle>
        {face && !face?.meshedFace && userState.userId && <FaceCroppedImg src={getFaceMeshedSrc(face)}/>}
        {face && face?.meshedFace && <FaceCroppedImg src={`data:image/jpeg;base64,${face.meshedFace}`} />}
      </FaceDetectedContainer>
      </>
    }
  </Container>;
};

export default CreateFace;
