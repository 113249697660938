import { FC, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Swap } from '../ApiTypes';
import { getSwaps } from '../api';
import SwapItem from '../components/SwapItem';
import { CenterContainer } from '../styles/Containers';
import { CircleLoading } from '../styles/Loadings';
import { useAsyncEffect } from '../utils';
import { Trans } from 'react-i18next';

const SwapListContainer = styled.div`
  display: flex;
  border-radius: 5px;
  margin: 30px;
  margin-bottom: 100px;
  gap: 15px;
  flex-wrap: wrap;

  @media (max-width: 560px) {
    gap: 20px;
    margin: 20px;
  }
`;

const LetsCreate = styled.div`
  font-size: 20px;
  color: rgb(254, 167, 0);
  margin-top: 30px;
`;

const SwapListPage: FC<{  }> = (props) => {
  const [swaps, setSwaps] = useState<Swap[]|null>(null);
  
  useAsyncEffect(async () => {
    setSwaps(await getSwaps());
  }, []);

  return <>
    {swaps?.length == 0 &&
      <CenterContainer>
        <Trans>You have not yet generated swap videos.</Trans><br />
        <Link to='/create'><LetsCreate><Trans>Let's create swap!</Trans></LetsCreate></Link>
      </CenterContainer>
    }
    <SwapListContainer>
    {swaps && swaps.map(swap => <SwapItem key={swap.swapId} swap={swap} />)}
    {!swaps && <CircleLoading />}
    </SwapListContainer>
  </>;
};

export default SwapListPage;
