import { FC, ReactNode } from 'react';
import styled from 'styled-components';

const Center = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
`;

const Container = styled.div`
  flex-direction: column;
  border-radius: 10px;
  background-color: #2b2b2b;
  color: white;
  padding: 30px;
  width: 500px;
  margin: 25px 20px 100px 20px;
  display: flex;
  font-weight: 500;
`;

export const CenterContainer: FC<{ children: ReactNode }> = (props) => {
  return <Center>
    <Container>
      {props.children}
    </Container>
  </Center>;
};

export const StepContainer = styled.dl`
  margin: 20px 0px;
`;

export const StepTitle = styled.dt`
  color: rgb(254, 167, 0);
  padding: 0 10px;
  font-size: 18px;
  font-weight: 700;
  display: flex;
  align-items: center;
  padding-left: 0px;
  padding-right: 0px;
`;

export const StepTitleBar = styled.div`
  height: 0px;
  border: 1px solid #3f321f;
  flex: 1;
  margin-left: 10px;
`;

export const StepContent = styled.dd`
  display: flex;
  flex-direction: column;
  padding: 20px 0 0 0;
  
  @media (max-width: 560px) {
    margin-left: 0px;
  }
`;

export const Visibility = styled.div<{ visible: boolean }>`
  display: ${props => props.visible ? "block" : "none"};
`;
