import { FC } from "react";
import "./TubyPlayer.css";
import ClientRender from "./components/ClientRender";
import Main from "./components/Main";
import { PlayerProps } from "./shared/types";

export const TubyPlayer: FC<PlayerProps> = props => {
  const { dimensions, primaryColor } = props;
  return (
    <div
      className="tuby"
      style={{
        ...(typeof dimensions === "number"
          ? { width: "100%", height: 0, paddingBottom: `${dimensions}%` }
          : typeof dimensions === "object"
          ? {
              width: dimensions.width,
              height: dimensions.height,
            }
          : { width: "100%", height: 0, paddingBottom: "56.25%" }),
        ...(primaryColor
          ? {
              "--tuby-primary-color": primaryColor,
            }
          : {}),
      }}
    >
      <ClientRender>
          <Main {...props}/>
      </ClientRender>
    </div>
  );
};

export default TubyPlayer;
