import { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Streaming, Swap, SwapStatus } from '../ApiTypes';
import { getSeverEndpoint, getWsSeverEndpoint, subscribeStreaming } from '../api';
import { useIdToken } from '../contexts/UserStateContext';
import { useAsyncEffect } from '../utils';
import VideoPlayer from './video/VideoPlayer';
import WebSocketPlayer from './video/WebSocketPlayer';

const ProgressBarContainer = styled.div`
  width: 75%;
  margin-bottom: 20px;

  @media (max-width: 560px) {
    width: 90%;
    margin: 0 auto;
    margin-bottom: 20px;
  }
`;

const ProgressBar = styled.div`
  width: 100%;
  height: 15px;
  background-color: #e0e0e0;
  border-radius: 5px;
`;

const ProgressFill = styled.div`
  height: 100%;
  background-color: rgb(254, 167, 0);
  border-radius: 5px;
  transition: width 10s linear;
`;

const ProgressText = styled.div`
  font-size: 14px;
  margin-top: 10px;
  margin-bottom: 5px;
`;

const LOADING_SECONDS = 10;

enum PlayerStatus {
  LOADED_VERSION_1,
  LOADED_VERSION_2,
  GENERATING,
  LOADING
};

type PlayerData = {
  status: PlayerStatus.LOADED_VERSION_1,
  swap: Swap,
  idToken: string
} | {
  status: PlayerStatus.LOADED_VERSION_2,
  swap: Swap,
  streaming: Streaming,
  idToken: string
} | {
  status: PlayerStatus.GENERATING | PlayerStatus.LOADING,
};

const getPlayerData = (idToken: string | null, swap: Swap|null, streaming: Streaming|null, generating: boolean|undefined): PlayerData => {
  if (idToken && swap && swap.version == 1) {
    return { status: PlayerStatus.LOADED_VERSION_1, swap, idToken };
  }
  if (idToken && swap && swap.version == 2 && streaming) {
    return { status: PlayerStatus.LOADED_VERSION_2, swap, streaming, idToken };
  }
  if (generating) {
    return { status: PlayerStatus.GENERATING };
  }
  if (swap && swap.version == 2 && swap.status == SwapStatus.STARTED_GENERATING) {
    return { status: PlayerStatus.GENERATING };
  }
  return { status: PlayerStatus.LOADING };
}

const SwapPlayer: FC<{ swap: Swap|null, generating?: boolean }> = (props) => {
  const [progress, setProgress] = useState(0);
  const [streamingRef, _] = useState<Streaming>({} as any);
  const [streaming, setStreaming] = useState<Streaming|null>(null);
  const idToken = useIdToken();

  useEffect(() => {
    setStreaming(null);
  }, [props.swap]);

  useAsyncEffect(async () => {
    if (props.swap?.version != 2 || !props.swap.streamingId) {
      return;
    }
    return await subscribeStreaming(props.swap.streamingId, latestStreaming => {
      if (latestStreaming.streamingId != (props.swap as any)?.streamingId) {
        return;
      }
      console.log("streaming");
      console.log(latestStreaming);
      Object.assign(streamingRef, latestStreaming);
      setStreaming(latestStreaming);
    });
  }, [props.swap?.version == 2 && props.swap.streamingId]);

  useEffect(() => {
    if (props.swap?.version != 2 || props.swap.status != SwapStatus.STARTED_GENERATING) {
      return;
    }
    setProgress(0);
    const timer = setInterval(() => {
      setProgress((prevProgress) => prevProgress + 0.1);
    }, 100);

    return () => {
      clearInterval(timer);
    }
  }, [props.swap]);

  const data = getPlayerData(idToken, props.swap, streaming, props.generating);

  if (data.status == PlayerStatus.LOADED_VERSION_1) {
    return <VideoPlayer src={`${getSeverEndpoint()}/swaps/${data.swap.swapId}/master.m3u8?authorization=${data.idToken}`} />;
  } else if (data.status == PlayerStatus.LOADED_VERSION_2) {
    const httpUrl = `https://storages.work/users/${data.swap.userId}/streamings/${data.streaming.streamingId}/master.m3u8`;
    const wsUrl = `${getWsSeverEndpoint()}/watch?authorization=${data.idToken}&streaming_id=${data.streaming.streamingId}`;
    return <WebSocketPlayer
        key={data.streaming.streamingId}
        httpSrc={httpUrl}
        wsSrc={wsUrl}
        fastLoadObjects={data.streaming.manifest}
        license={data.streaming.license}
        getUseHttp={() => streamingRef.status == "generated"} />;
  } else if (data.status == PlayerStatus.GENERATING) {
    return <ProgressBarContainer>
      <ProgressText>Generating...</ProgressText>
      <ProgressBar>
        <ProgressFill style={{ width: `${Math.min(progress / LOADING_SECONDS * 100, 100)}%` }} />
      </ProgressBar>
    </ProgressBarContainer>;
  } else {
    return <VideoPlayer />;
  }
};

export default SwapPlayer;
