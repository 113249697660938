import { getAuth, signOut } from 'firebase/auth';
import { FC, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { UserData } from '../ApiTypes';
import { UserStateContext } from '../contexts/UserStateContext';
import { StepContainer, StepContent, StepTitle, StepTitleBar } from '../styles/Containers';
import { MiniCircleLoading } from '../styles/Loadings';
import { useAsyncEffect } from '../utils';
import { Trans } from 'react-i18next';

const Title = styled.div`
  text-align: center;
  color: white;
  font-weight: 700;
  font-size: 22px;
  margin-bottom: 15px;
`;

const Center = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  margin-bottom: 100px;
`;

const Container = styled.div`
  flex-direction: column;
  border-radius: 10px;
  // border: 1px solid #424242;
  background-color: #2b2b2b;
  color: white;
  padding: 30px 20px;
  margin: 25px 20px 10px 20px;
  display: flex;
  font-weight: 500;
  box-sizing: border-box;

  @media (min-width: 560px) {
    width: 500px;
  }
`;

const DeleteAccount = styled.div<{ disabled?: boolean }>`
  margin-top: 10px;
  border-radius: 10px;
  height: 30px;

  cursor: ${props => props.disabled ? 'default' : 'pointer'};
  background-color: #AF1C1C;
  border: 1px solid #3d3d3d;

  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: #DC2625;
  }
`;

const formatDate = (timestamp: number) => {
  const date = new Date(timestamp * 1000);
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  return `${year}/${month.toString().padStart(2, '0')}/${day.toString().padStart(2, '0')}`;
}

export const Button = styled.span<{ disabled?: boolean }>`
  margin-top: 10px;
  border-radius: 10px;
  height: 44px;

  color: ${props => props.disabled ? 'gray' : 'white'};
  cursor: ${props => props.disabled ? 'default' : 'pointer'};
  background-color: ${props => props.disabled ? '#141414' : '#1d1d1d'};
  // border: 1px solid #3d3d3d;

  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: ${props => props.disabled ? '#141414' : '#242424'};
  }
`;

const Logout: FC<{}> = (props) => {
  const [loading, setLoading] = useState(false);
  const userState = useContext(UserStateContext);

  const logout = async () => {
    if (loading || !userState.isSigned) {
      return;
    }
    setLoading(true);
    await signOut(getAuth());
    userState.update();
    setLoading(false);
  };

  return <Button id="to-logout" disabled={loading} onClick={logout}>
    {loading && <MiniCircleLoading />}
    <Trans>Logout</Trans>
  </Button>;
};

const MessageContainer = styled.div`
  margin-top: 5px;
  font-size: 14px;
  color: gray;
`;

const P = styled.p`
  margin: 5px 0px;
`;

const AccountPage: FC<{}> = (props) => {
  const navigate = useNavigate();
  const userState = useContext(UserStateContext);
  const [userData, setUserData] = useState<UserData|null>(null);

  useAsyncEffect(async () => {
    setUserData(await userState.getUserData());
  }, [userState]);

  useEffect(() => {
    if (!userState.isLoading && !userState.isSigned) {
      navigate("/");
    }
  }, [userState]);

  const onDeleteAccount = () => {
    navigate("/delete-account");
  };
  
  return <>
    <Center>
      <Container>
        <Title>
          <Trans>Account details</Trans>
        </Title>
        <StepContainer>
          <StepTitle>
            <Trans>Account</Trans><StepTitleBar />
          </StepTitle>
          {userState.user?.email && <StepContent>
            <P><Trans>Email</Trans>: <span id="my-email">{userState.user.email}</span></P>
          </StepContent>}
          {userState.user && <StepContent>
            <P><Trans>User ID</Trans>: <span id="my-user-id">{userState.user.uid}</span></P>
          </StepContent>}
          {userState.user && <StepContent>
            <P><Trans>Credit</Trans>: <span id="my-user-id">{userData?.credit || 0}</span></P>
          </StepContent>}
        </StepContainer>
        <StepContainer>
          <StepTitle>
            <Trans>Account</Trans><StepTitleBar />
          </StepTitle>
          <StepContent>
            <DeleteAccount onClick={() => onDeleteAccount()}><Trans>Delete account</Trans></DeleteAccount>
          </StepContent>
        </StepContainer>
        <StepContainer>
          <StepTitle>
            <Trans>Others</Trans><StepTitleBar />
          </StepTitle>
          <StepContent>
            <Logout />
          </StepContent>
        </StepContainer>
      </Container>
    </Center>
  </>;
};

export default AccountPage;
