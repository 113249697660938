import { FC } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Swap } from '../ApiTypes';
import { formatDate } from '../utils';
import SourceView from './SourceView';

const SwapItemContainer = styled.div`
  background-color: #2b2b2b;
  border-radius: 10px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  // border: 1px solid #424242;
  color: white;
`;

const OriginalTitle = styled.div`
  margin-top: 10px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  line-height: 20px;
  overflow: hidden;
  width: 200px;

  @media (max-width: 560px) {
    width: 100%;
  }
`;

const SwapInfo = styled.div`
  font-size: 14px;
  color: #8a8a8a;
`;

const SwapItem: FC<{ swap: Swap }> = (props) => {
  return <Link to={`/swaps/${props.swap.swapId}`}>
    <SwapItemContainer>
      <SourceView original={props.swap.original} face={props.swap.face} swap={props.swap} width={200} />
      <OriginalTitle>{props.swap.original.detected.title}</OriginalTitle>
      <SwapInfo>
        {formatDate(props.swap.createdAt)}
      </SwapInfo>
    </SwapItemContainer>
  </Link>;
};

export default SwapItem;
