import { getAuth, signOut } from 'firebase/auth';
import { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { deleteAccount } from '../api';
import { CenterContainer } from '../styles/Containers';
import { MiniCircleLoading } from '../styles/Loadings';
import { Trans } from 'react-i18next';

const Title = styled.div`
  text-align: center;
  color: white;
  font-weight: 700;
  font-size: 22px;
  margin-bottom: 15px;
`;

const DeleteAccount = styled.div<{ disabled?: boolean }>`
  margin-top: 10px;
  border-radius: 10px;
  padding: 5px;

  cursor: ${x => x.disabled ? 'default' : 'pointer'};
  background-color: #AF1C1C;
  border: 1px solid #3d3d3d;

  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: #DC2625;
  }
`;

const DeleteAccountPage: FC<{}> = (props) => {
  const navigate = useNavigate();
  const [deleting, setDeleting] = useState(false);

  const handleSubmit = async () => {  
    if (deleting) {
      return;
    }
    setDeleting(true);
    await deleteAccount();
    await signOut(getAuth());
    navigate("/");
  };

  return <CenterContainer>
    <Title>
      <Trans>Delete Account</Trans>
    </Title>
    <br />
    <Trans>Once you delete your account,</Trans> <br />
    - <Trans>All videos you have generated will be deleted and will be permanently unavailable for viewing.</Trans><br />
    - <Trans>All uploaded videos and face photos will be deleted.</Trans><br />
    - <Trans>Your subscription will be canceled and you will no longer be billed.</Trans><br />
    - <Trans>You can't reactivate your account again.</Trans><br />
    <br />
    <DeleteAccount disabled={deleting} onClick={() => handleSubmit()}>
      {!deleting && <Trans>I understand everything and will delete the account.</Trans>}
      {deleting && <><MiniCircleLoading /><Trans>Deleting account</Trans></>}
    </DeleteAccount>
  </CenterContainer>;
};

export default DeleteAccountPage;