import { FC, useContext } from "react";
import { Trans } from "react-i18next";
import { Link, NavLink, Outlet, RouterProvider, ScrollRestoration, createBrowserRouter } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';
import './App.css';
import { getPornMode, getSiteName } from './api';
import SelectLanguage from "./components/SelectLanguage";
import { UserStateContext, useUserStateContext } from './contexts/UserStateContext';
import AccountPage from './pages/AccountPage';
import CleanHomePage from "./pages/CleanHomePage";
import DeleteAccountPage from "./pages/DeleteAccountPage";
import InvaildLoginLinkPage from "./pages/InvaildLoginLinkPage";
import LoginPage from './pages/LoginPage';
import NewSwapPage from './pages/NewSwapPage';
import ReportIssuePage from "./pages/ReportIssuePage";
import SCTLSPage from "./pages/SCTLSPage";
import SwapListPage from './pages/SwapListPage';
import TemporarySwapPage from "./pages/TemporarySwapPage";
import WatchSwapPage from './pages/WatchSwapPage';
import HomePage from './pages/lp/HomePage';

const MainContainer = styled.div`
  color: #dcdcdc;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100vw;
  font-family: ui-sans-serif,system-ui,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji;
`;

const Gaming = keyframes`
  100% { background-position-x: 200%; }
`;

const Title = styled.div`
  color: #fa6d7d;
  font-size: 70px;
  font-weight: bold;
  text-align: center;
  animation: ${Gaming} 2s linear infinite;
`;

const Header = styled.header`
  color: black;
  display: flex;
  flex-direction: row;
`;

const HeaderItem = styled.header`
  color: rgb(255, 255, 238);
  font-size: 17px;
  font-weight: 500;
  cursor: pointer;
  border-radius: 5px;
  padding: 5px 20px;

  @media (max-width: 560px) {
    padding: 5px 10px;
  }
`;

const HeaderItemLogo = styled.header`
  color: rgb(255, 255, 238);
  font-size: 20px;
  font-weight: 700;
  cursor: pointer;
  border-radius: 5px;
  padding: 20px;

  @media (max-width: 560px) {
    padding-right: 5px;
  }

  @media (max-width: 450px) {
    width: 0px;
    padding: 20px 0px;
    margin-left: 10px;
    overflow-x: hidden;
  }
`;

const StyledLogoLink = styled(NavLink)`
  border-bottom: 2px solid #424242;
`;

const Main = styled.main`
  flex: 1;
`;

const StyledNavLink = styled(NavLink)`
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 2px solid #424242;

  &.active {
    border-bottom: 2px solid rgb(254, 167, 0);
  }

  &:hover {
    background-color: #272727;
  }

  @media (max-width: 560px) {
    flex: 1;
    &:hover {
      background-color: transparent;
    }
  }
`;

const Footer = styled.footer`
  background-color: #292929;
  padding: 40px;
  padding-bottom: 60px;
`;

const FooterHeader = styled.h1`
  font-size: 18px;
`;

const FooterItem = styled.div`
  color: #a0a0a0;
  font-weight: 500;
`;

const UserIcon = styled.img`
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: #424242;
  padding: 5px;
  margin: 0px 20px;

  @media (max-width: 560px) {
    padding: 3px;
  }
`;

const Space = styled.div`
  flex: 1;
  border-bottom: 2px solid #424242;
`;

const Credit = styled.span`
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: bold;
  padding-right: 10px;
  border-bottom: 2px solid #424242;
`;

const CreditIcon = styled.img`
  width: 25px;
  margin-right: 7px;
  -webkit-filter: invert(100%);
  filter: invert(100%);
`;

const Bottom = styled.div`
  height: 75px;
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  padding-right: 20px;
`;

const Hidden = styled.div`
  opacity: 0;
  height: 0px;
`;

const Layout: FC<{}> = () => {
  const userState = useContext(UserStateContext);

  return <MainContainer>
      <title>{getSiteName()}</title>
      <Header>
        <StyledLogoLink to="/"><HeaderItemLogo>{getSiteName()}</HeaderItemLogo></StyledLogoLink>
        <StyledNavLink to="/"><HeaderItem><Trans>Home</Trans></HeaderItem></StyledNavLink>
        <StyledNavLink to="/create"><HeaderItem><Trans>Create</Trans></HeaderItem></StyledNavLink>
        {userState.isSigned && <StyledNavLink to="/swaps"><HeaderItem><Trans>Videos</Trans></HeaderItem></StyledNavLink>}
        <Space />
        <SelectLanguage />
        {!userState.isSigned && <StyledNavLink to="/login"><HeaderItem><Trans>Login</Trans></HeaderItem></StyledNavLink>}
        {userState.isSigned && <StyledNavLink to="/account">
            <UserIcon src="/imgs/user.svg"/>
        </StyledNavLink>}
      </Header>
      <Main>
        <Outlet />
      </Main>
      <Footer>
        <FooterHeader><Trans>Information</Trans></FooterHeader>
        <Link to="/"><FooterItem><Trans>About</Trans></FooterItem></Link>
      </Footer>
      <Bottom>
        © 2024 {getSiteName()}. <Trans>All Rights Reserved.</Trans>
      </Bottom>
      <ScrollRestoration />
    </MainContainer>;
}

const App = () => {
  const userState = useUserStateContext();

  const router = createBrowserRouter([
    {
      path: '/',
      element:  <Layout />,
      children: [
        {
          index: true,
          element: getPornMode() ?  <HomePage /> : <CleanHomePage />,
        },
        {
          path: '/home',
          element: getPornMode() ?  <HomePage /> : <CleanHomePage />,
        },
        {
          path: '/swaps',
          element: <SwapListPage />,
        },
        {
          path: '/swaps/:swapId',
          element: <WatchSwapPage />,
        },
        {
          path: '/temporary_swaps/:tmpSwapId',
          element: <TemporarySwapPage />,
        },
        {
          path: '/temporary_swaps_loggedin/:tmpSwapId',
          element: <TemporarySwapPage loggedin={true} />,
        },
        {
          path: '/temporary_swaps_canceled/:tmpSwapId',
          element: <TemporarySwapPage canceled={true}/>,
        },
        {
          path: '/create',
          element: <NewSwapPage />,
        },
        {
          path: '/report-issue',
          element: <ReportIssuePage />,
        },
        {
          path: '/invaild-login-link',
          element: <InvaildLoginLinkPage />
        },
        {
          path: '/login',
          element: <LoginPage />,
        },
        {
          path: '/account',
          element: <AccountPage />,
        },
        {
          path: '/specified-commercial-transactions-law-statement',
          element: <SCTLSPage />,
        },
        {
          path: '/delete-account',
          element: <DeleteAccountPage />
        }
      ],
    }

  ]);

  return <UserStateContext.Provider value={userState}>
    <RouterProvider router={router} />
    <Hidden>
      <img src="/lp/card/banner.gif" />
    </Hidden>
  </UserStateContext.Provider>;
}

export default App;
