import { FC, ReactNode } from 'react';
import styled from 'styled-components';
import { EmptyCircleLoading } from './Loadings';

const InspireMessageContainer = styled.div`
  font-size: 15px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  opacity: 0.7;
`;

const InspireMessageIcon = styled.img`
  width: 20px;
  margin-top: 2px;
  margin-right: 5px;
`;

export const InspireMessage: FC<{ children: ReactNode }> = (props) => {
  return <InspireMessageContainer>
    <InspireMessageIcon src="/imgs/fire.png"/>
    {props.children}
  </InspireMessageContainer>;
};

const SuccessfulMessageContainer = styled.div`
  display: flex;
  align-items: center;
  font-size: 15px;
  margin-bottom: 10px;
`;

const SuccessfulMessageText = styled.div`
  text-align: left;
  font-size: 15px;
  opacity: 0.7;
  font-weight: bold;
  color: #27b24a;
  flex: 10;
  margin-right: 10px;
`;

const SuccessfulMessageIcon = styled.img`
  width: 30px;
  margin-top: 2px;
  margin-right: 5px;
  opacity: 0.7;
`;

const SuccessfulMessageSpace = styled.div`
  flex: 1;
`;

export const SuccessfulMessage: FC<{ children: ReactNode, noSpace?: boolean }> = (props) => {
  return <SuccessfulMessageContainer>
    {!props.noSpace && <SuccessfulMessageSpace />}
    <SuccessfulMessageIcon src="/imgs/check.png"/>
    <SuccessfulMessageText>{props.children}</SuccessfulMessageText>
    {!props.noSpace && <SuccessfulMessageSpace />}
  </SuccessfulMessageContainer>;
};

const FailureMessageContainer = styled.div`
  display: flex;
  align-items: center;
  font-size: 15px;
  margin-bottom: 10px;
  width: 100%;
`;

const FailureMessageText = styled.div`
  text-align: left;
  font-size: 15px;
  opacity: 0.7;
  font-weight: bold;
  color: #f44336;
  flex: 10;
  margin-right: 10px;
`;

export const FailureMessage: FC<{ children: ReactNode }> = (props) => {
  return <FailureMessageContainer>
    <SuccessfulMessageIcon src="/imgs/error.png"/>
    <FailureMessageText>{props.children}</FailureMessageText>
  </FailureMessageContainer>;
};

export const ErrorMessage = styled.span`
  color: rgb(244, 33, 46);
`;

const EmptyMessageContainer = styled.div`
  background-color: #2b2b2b;
  border: 1px solid #424242;
  border-radius: 10px;
  padding: 10px;
  display: flex;
  align-items: center;
`;

const PayGuidanceMessageContainer = styled.div`
  background-color: #2b2b2b;
  border: 1px solid #424242;
  border-radius: 10px;
  padding: 10px;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  flex-direction: column;
  align-items: flex-start;
`;

export const EmptyMessage: FC<{ children: ReactNode }> = (props) => {
  return <EmptyMessageContainer>
    {props.children}
  </EmptyMessageContainer>
};

export const LoadingMessage: FC<{}> = (props) => {
  return <EmptyMessageContainer>
    <EmptyCircleLoading />Loading...
  </EmptyMessageContainer>;
};

export const PayGuidanceMessage: FC<{ children: ReactNode }> = (props) => {
  return <PayGuidanceMessageContainer>
    {props.children}
  </PayGuidanceMessageContainer>
};