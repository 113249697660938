import { FC } from 'react';
import { Trans } from 'react-i18next';
import styled from 'styled-components';

const Center = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
`;

const Container = styled.div`
  flex-direction: column;
  border-radius: 10px;
  // border: 1px solid #424242;
  background-color: #2b2b2b;
  color: white;
  padding: 30px;
  width: 500px;
  margin: 25px 20px 200px 20px;
  display: flex;
  font-weight: 500;
`;

const Title = styled.div`
  text-align: center;
  color: white;
  font-weight: 700;
  font-size: 22px;
  margin-bottom: 15px;
`;

const  InvaildLoginLinkPage: FC<{ email?: string, loginUrl?: string }> = (props) => {
  return <Center>
    <Container>
      <Title>
        <Trans>Invaild login link</Trans>
      </Title>
      <Trans>This login link is invalid. You have either already logged in using this link, or a newer login link has been sent to your email address. Please check your email or log in again from the login page.</Trans>
    </Container>
  </Center>;
};

export default  InvaildLoginLinkPage;
