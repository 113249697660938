
const VolumeMuted = ({ ...others }) => {
  return (
    <svg
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...others}
    >
      <path
        d="M37.5 25.0139C37.5 20.0945 34.6667 15.8699 30.5556 13.8132V19.9555L37.3611 26.7649C37.4444 26.209 37.5 25.6253 37.5 25.0139ZM44.4444 25.0139C44.4444 27.6265 43.8889 30.0723 42.9444 32.3513L47.1389 36.5481C48.9722 33.1017 50 29.1829 50 25.0139C50 13.1184 41.6944 3.16843 30.5556 0.667039V6.36465C38.5833 8.75487 44.4444 16.2034 44.4444 25.0139V25.0139ZM3.52778 0L0 3.50195L13.1111 16.6481H0V33.3241H11.1111L25 47.2207V28.5158L36.8056 40.328C34.9444 41.7732 32.8611 42.9127 30.5556 43.6076V49.333C34.3889 48.4714 37.8611 46.6926 40.8056 44.3024L46.4722 50L50 46.4703L25 21.4564L3.55556 0H3.52778ZM24.9722 2.75153L19.1667 8.53252L24.9722 14.3413V2.77932V2.75153Z"
        fill="white"
      />
    </svg>
  );
};

export default VolumeMuted;
