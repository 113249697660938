import { User, getAuth } from "firebase/auth";
import { Bytes, Timestamp } from 'firebase/firestore';
import { getUserData } from "./api";

//===========================================================================
// User
//===========================================================================

export type UserData = {
  downloadable?: boolean
  credit?: number;
};

//===========================================================================
// Face
//===========================================================================

export type DetectedFace = {
  userId: undefined;
  faceId: undefined;
  croppedFace: string;
  meshedFace: string;
} | {
  userId: string;
  faceId: string;
  croppedFace: undefined;
  meshedFace: undefined;
} | {
  userId: string;
  faceId: string;
  croppedFace: string;
  meshedFace: string;
};

export type Face = {
  createdAt: Timestamp;
  userId: string;
  faceId: string;
  hash?: string;
} & DetectedFace;

//===========================================================================
// Original
//===========================================================================

export interface DetectedOriginal {
  url: string;
  detected: {
    imageUrl: string;
    title: string;
  }
}

export interface Original extends DetectedOriginal {
  userId: string;
  originalId: string;
}

//===========================================================================
// Swap
//===========================================================================

export enum SwapStatus {
  PENDING = "pending",
  STARTED_GENERATING = "started_generating",
  GENERATED = "generated"
}

interface SwapVersion1 {
  version: 1;
  userId: string;
  swapId: string;
  faceId: string;
  originalId: string;
  createdAt: Timestamp;
}

interface SwapVersion2 {
  version: 2;
  userId: string;
  swapId: string;
  faceId: string;
  streamingId: string;
  originalUrl: string;
  createdAt: Timestamp;
  status: SwapStatus;
}

export type Swap = (SwapVersion1 | SwapVersion2) & {
  original: Original;
  face: Face;
};

//===========================================================================
// Temporary Swap
//===========================================================================

export interface TemporarySwapRequest {
  originalUrl: string;
  originalThumbnailUrl: string;
  originalTitle: string;
  croppedFace: string;
  meshedFace: string;
  baseUrl: string;
}

export interface TemporarySwap {
  swapRequest: TemporarySwapRequest;
}

//===========================================================================
// Streaming
//===========================================================================

export interface Streaming {
  userId: string;
  streamingId: string;
  status: "generated"|"generating";
  license: string;
  manifest: { [key: string]: Bytes }
};

//===========================================================================
// User
//===========================================================================

export class UserState {
  _user: User | null | undefined;
  _userData: UserData | null;
  _onUpdate?: () => void;

  constructor(user: User|null|undefined, onUpdate?: () => void) {
    this._user = user;
    this._onUpdate = onUpdate;
    this._userData = null;
  }

  update() {
    this._user = getAuth().currentUser;
    if (this._onUpdate) {
      this._onUpdate();
    }
  }

  async getUserData() {
    if (!this.user) {
      return null;
    }
    if (!this._userData) {
      this._userData = await getUserData();
    }
    return this._userData;
  }

  get user() {
    return this._user as User;
  }

  get isLoading() {
    return this._user === undefined;
  }

  get isSigned() {
    return this._user && !this._user.isAnonymous;
  }

  get userId() {
    return this._user?.uid;
  }
}