import { FC } from 'react';
import styled from 'styled-components';

const Tabs = styled.div`
	max-width: 700px;
  	display: flex;
	position: relative;
	background-color: #2b2b2b;
	box-shadow: 0 0 1px 0 rgba(#185ee0, 0.15), 0 6px 12px 0 rgba(#185ee0, 0.15);
	border-radius: 6px;
	margin-bottom: 20px;
	* {
		z-index: 2;
	}
`;

const Tab = styled.span<{ selected: boolean }>`
  display: flex;
	flex: 1;
	align-items: center;
	justify-content: center;
	height: 40px;
	width: 200px;
	font-size: 16px;
	font-weight: 500;
	cursor: pointer;
	transition: color 0.15s ease-in;
	color: ${props => props.selected ? 'black' : 'white'};
`;

const Glider = styled.span<{ index: number, tabcount: number }>`
	position: absolute;
	display: flex;
	height: 100%;
	width: ${props => `${100 / props.tabcount}%`};
	background-color: rgb(254, 167, 0);
	z-index: 1;
	border-radius: 6px;
	transition: 0.25s ease-out;
	transform: ${props => `translateX(${props.index * 100}%)`};
`;

const SlideTab: FC<{ index: number, tabs: string[], onChange(mode: number): void }> = (props) => {
	return <Tabs>
		{props.tabs.map((tab, i) => <Tab key={i} selected={i == props.index} onClick={e => props.onChange(i)}>{tab}</Tab>)}
		<Glider index={props.index} tabcount={props.tabs.length}/>
	</Tabs>;
};

export default SlideTab;
