import { FC, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { DetectedOriginal, Original } from '../../ApiTypes';
import { getOriginals } from '../../api';
import { EmptyMessage, LoadingMessage } from '../../styles/Messages';
import { removeDuplicates, useAsyncEffect } from '../../utils';
import { Trans } from 'react-i18next';

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const OriginalList = styled.div`
  display: flex;
  flex-wrap: wrap;
  overflow-y: auto; 
  gap: 10px;

  @media (max-width: 560px) {
    max-height: 400px
  }
  @media (min-width: 560px) {
    max-height: 550px;
  }
`;

const OriginalItemContainer = styled.div<{ selected: boolean, disabled: boolean }>`
  background-color: ${props => props.selected ? 'rgb(254, 167, 0)' : '#2b2b2b'};
  color: ${props => props.selected ? 'black' : 'white'};
  border-radius: 10px;
  padding: 10px;
  gap: 10px;
  // border: 1px solid #424242;
  display: flex;
  flex-direction: column;
  cursor: ${props => props.disabled ? "default" : "pointer"};
  width: 200px;
  pointer-events: ${props => props.disabled ? 'none' : 'auto'};

  @media (max-width: 560px) {
    box-sizing: border-box;
    width: calc(50% - 5px);
  }
`;

const OriginalThumbnailImg = styled.img`
  border-radius: 10px;
  aspect-ratio: 16 / 9;
  object-fit: cover;
  object-position: center; 
  overflow: hidden;

  @media (max-width: 560px) {
    width: 100%;
    height: auto;
  }
`;

const OriginalDeletedText = styled.div`
  border-radius: 10px;
  aspect-ratio: 16 / 9;
  overflow: hidden;
  font-size: 16px;
  color: darkred;
  font-weight: bold;
  display: flex;
  align-items: center;
  text-align: center;
  background: lightgray;

  @media (max-width: 560px) {
    width: 100%;
    height: auto;
  }
`;

const OriginalTitle = styled.div`
  font-size: 12px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  flex: 1;
  line-height: 16px;
  overflow: hidden;
`;

const OriginalItem: FC<{ original: Original, selected: boolean, onSelect(): void }> = (props) => {
  const [hasLoaded, setHasLoaded] = useState<boolean|null>(null);
  const imgRef = useRef<HTMLImageElement|null>(null);

  useEffect(() => {
    const img = imgRef.current;
    if (!img) {
      return;
    }
    if (img.complete) {
      setHasLoaded(img.naturalHeight > 100);
    } else {
      const onLoad = () => {
        setHasLoaded(img.naturalHeight > 100);
      };
      img.addEventListener("load", onLoad);
      return () => {
        img.removeEventListener("load", onLoad);
      };
    }
  }, [props.original.detected.imageUrl, imgRef.current]);

  return <OriginalItemContainer selected={props.selected} disabled={hasLoaded !== true} onClick={() => props.onSelect()}>
    <OriginalTitle>{props.original.detected.title}</OriginalTitle>
    {hasLoaded !== false && <OriginalThumbnailImg ref={imgRef} src={props.original.detected.imageUrl} />}
    {hasLoaded === false && <OriginalDeletedText><Trans>This video has been deleted.</Trans></OriginalDeletedText>}
  </OriginalItemContainer>;
};

const SelectOriginal: FC<{ updationKey: any, original: DetectedOriginal|null, onSelect(original: DetectedOriginal|null): void }> = (props) => {
  const [originals, setOriginals] = useState<Original[]|null>(null);

  useAsyncEffect(async () => {
    setOriginals(await getOriginals());
  }, [props.updationKey]);

  return <Container>
    {originals && <OriginalList>
      {removeDuplicates(originals.filter(x => x.detected && !x.url.includes("xhamster.com")), x => x.url).map(original => <OriginalItem key={original.url} onSelect={() => props.onSelect(original)} selected={original.url == props.original?.url} original={original} />)}
    </OriginalList>}
    {originals && originals.length == 0 && <EmptyMessage>
      <Trans>You have not yet imported videos</Trans>
    </EmptyMessage>}
    {!originals && <LoadingMessage />}
  </Container>;
};

export default SelectOriginal;
