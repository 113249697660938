import { FC, useState } from 'react';
import styled from 'styled-components';
import { DetectedOriginal } from '../../ApiTypes';
import { detectVideo, getPornMode } from '../../api';
import { TextInput } from '../../styles/Inputs';
import { CircleLoading } from '../../styles/Loadings';
import { FailureMessage, SuccessfulMessage } from '../../styles/Messages';
import { Trans } from 'react-i18next';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const VideoDetectedContainer = styled.div`
  max-width: 300px;
  display: flex;
  align-items: center;
  flex-direction: column;
  border: 1px solid #424242;
  border-radius: 10px;
`;

const VideoDetectedTitle = styled.div`
  font-weight: bold;
  margin: 10px 0;
`;

const VideoDetails = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const VideoTitle = styled.div`
  margin-top: 10px;
  margin-left: 10px;
  flex: 1;
  padding: 5px;
`;

const ThumbnailImg = styled.img`
  width: 100%;
  border-radius: 5px;
`;

const SiteLink = styled.a.attrs({ target: '_blank' })`
  color: rgb(254, 167, 0);
  text-decoration: underline;
`;

const CompatibleText = styled.div`
  text-align: left;
  width: 100%;
`;

function validateUrl(url: string) {
  var pattern = new RegExp('^(https?:\\/\\/)?'+ // プロトコル
                           '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // ドメイン名
                           '((\\d{1,3}\\.){3}\\d{1,3}))'+ // またはIP (v4) アドレス
                           '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // ポート番号とパス
                           '(\\?[;&a-z\\d%_.~+=-]*)?'+ // クエリ文字列
                           '(\\#[-a-z\\d_]*)?$','i'); // フラグメントロケータ
  return pattern.test(url);
}

enum Status {
  URL_EMPTY,
  URL_INVAILD,
  VIDEO_DETECTING,
  VIDEO_FAILED_DETECT,
  VIDEO_DETECTED
}

const CreateOriginal: FC<{ original: DetectedOriginal|null, onCreate(original: DetectedOriginal|null): void }> = (props) => {
  const [status, setStatus] = useState<Status>(props.original ? Status.VIDEO_DETECTED : Status.URL_EMPTY);
  const [original, setOriginal] = useState<DetectedOriginal|null>(props.original);
  const [url, setUrl] = useState<string>(props.original?.url || "");
  const [timer, setTimer] = useState<NodeJS.Timeout|null>(null);

  const sendVideoUrl = async (url: string) => {
    try {
      if (!url.startsWith("http")) {
        url = "https://" + url;
      }
      const original = await detectVideo(url);
      setStatus(Status.VIDEO_DETECTED);
      setOriginal(original);
      props.onCreate(original);
    } catch {
      setStatus(Status.VIDEO_FAILED_DETECT);
    }
  };

  const changeUrlTimer = async (url: string) => {
    setUrl(url);
    setOriginal(null);
    if (!url) {
      setStatus(Status.URL_EMPTY);
      return;
    }
    if (!validateUrl(url)) {
      setStatus(Status.URL_INVAILD);
      return;
    }
    setStatus(Status.VIDEO_DETECTING);

    if (timer) {
      clearTimeout(timer);
    }
    const newTimer = setTimeout(async () => {
      await sendVideoUrl(url);
    }, 100);
    setTimer(newTimer);
  }
// , <SiteLink href='https://xhamster.com'>xHamster</SiteLink>
  return <Container>
    <CompatibleText>
      <Trans>Video URL {getPornMode() && <>(compatible with <SiteLink href='https://xvideos.com'>XVideos</SiteLink>, <SiteLink href='https://pornhub.com'>PornHub</SiteLink>)</>}</Trans>
    </CompatibleText>
    <TextInput
      id="to-type-video-url"
      type="url"
      placeholder='https://'
      required
      value={url}
      onChange={async e => await changeUrlTimer(e.target.value)}
    />
    {status == Status.URL_INVAILD && <FailureMessage><Trans>Please enter the correct URL.</Trans></FailureMessage>}
    {status == Status.VIDEO_FAILED_DETECT && <FailureMessage><Trans>Video could not be imported.</Trans></FailureMessage>}
    {status == Status.VIDEO_DETECTING && <CircleLoading /> }
    {status == Status.VIDEO_DETECTED && <SuccessfulMessage noSpace={true}><Trans>Successfully imported video.</Trans></SuccessfulMessage>}
    {status == Status.VIDEO_DETECTED && original &&
      <VideoDetectedContainer>
        <VideoDetectedTitle><Trans>Imported video</Trans></VideoDetectedTitle>
        <ThumbnailImg src={original.detected.imageUrl} />
        <VideoDetails>
          <VideoTitle>{original.detected.title || <Trans>Loading...</Trans>}</VideoTitle>
        </VideoDetails>
      </VideoDetectedContainer>
    }
  </Container>;
};

export default CreateOriginal;
