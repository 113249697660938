import { FC, useState } from 'react';
import { Trans } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { Swap, SwapStatus, UserData } from '../ApiTypes';
import { getFaceCroppedSrc } from '../Storage';
import { checkIssue, checkoutSwap, getSwap } from '../api';
import SwapList from '../components/SwapList';
import SwapPlayer from '../components/SwapPlayer';
import { useUserStateContext } from '../contexts/UserStateContext';
import { CircleLoading, MiniCircleLoading } from '../styles/Loadings';
import { useAsyncEffect } from '../utils';

const WatchSwapContainer = styled.div`
  display: flex;
  margin: 40px;
  gap: 20px;

  @media (max-width: 560px) {
    flex-direction: column;
    margin: 0px;
  }
`;

const MainContainer = styled.div`
  flex: 1;
`;

const SideContainer = styled.div`
  @media (max-width: 560px) {
    margin: 0px 20px;
  }
`;

const SwapDescriptionContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 15px;
  gap: 20px;
`;

const FaceImg = styled.img`
  border-radius: 50%;
  width: 25%;
  height: 25%;
  object-fit: cover; 
  overflow: hidden;
  min-width: 60px;
  max-width: 80px;
`;

const SwapDescriptionText = styled.div`

`;

const SwapTitle = styled.div`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
`;

const InfoContainer = styled.div`
  border-radius: 10px;
  background-color: #2b2b2b;
  padding: 10px;
  margin: 20px;
  @media (min-width: 560px) {
    margin: 0px;
    margin-bottom: 20px;
  }
`;

const SwapDescription: FC<{ swap: Swap|null }> = (props) => {
  return <SwapDescriptionContainer>
    {props.swap && <FaceImg src={getFaceCroppedSrc(props.swap.face)} />}
    <SwapDescriptionText>
      <SwapTitle>{props.swap ? props.swap.original.detected.title : <Trans>Loading...</Trans>}</SwapTitle>
    </SwapDescriptionText>
  </SwapDescriptionContainer>
}

export const ReportButton = styled.span<{ disabled: boolean }>`
  margin-top: 20px;
  border-radius: 5px;
  pointer-events: ${props => props.disabled ? 'none' : 'auto'};
  height: 30px;
  width: 140px;
  font-size: 16px;

  cursor: ${props => props.disabled ? 'default' : 'pointer'};
  background-color: ${props => props.disabled ? '#926613' : 'rgb(254, 167, 0)'};
  color: black;

  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: ${props => props.disabled ? '#926613' : '#f8be53'};
  }
`;

const WatchSwapPage: FC<{}> = (props) => {
  const [swap, setSwap] = useState<Swap|null>(null);
  const { swapId } = useParams();

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const generating = searchParams.get("generating") ? true : undefined;
  const userState = useUserStateContext();
  const navigate = useNavigate();

  const [userData, setUserData] = useState<UserData|null>(null);

  const [haveIssue, setHaveIssue] = useState<boolean|null|undefined>(undefined);

  useAsyncEffect(async () => {
    setUserData(await userState.getUserData());
  }, [userState]);

  useAsyncEffect(async () => {
    if (!swapId || !userState.isSigned) {
      return;
    }
    setHaveIssue(undefined);
    setSwap(null);
    const swap = await getSwap(swapId);
    console.log(swap);
    setSwap(swap);
  }, [swapId, userState]);

  useAsyncEffect(async () => {
    if (!swapId || swap?.version != 2) {
      return;
    }
    if (swap.status == SwapStatus.PENDING) {
      const result = await checkoutSwap(swapId);
      if (result.actionUrl) {
        window.location.href = result.actionUrl;
      } else {
        const swap = await getSwap(swapId);
        console.log(swap);
        setSwap(swap);
      }
    }
  }, [swap]);

  const handleReportIssue = async () => {
    if (!swapId) {
      return;
    }
    setHaveIssue(null);
    const result = await checkIssue(swapId);
    if (result) {
      navigate("/report-issue");
    } else {
      setHaveIssue(false);
    }
  };

  // 動画を生成中の場合はSwapPlayer内でプログレスバーを表示するので、
  // 動画を生成している場合は、Swapの取得中もSwapPlayerを表示する。
  const isGeneratedAndLoading = !generating && swap && swap.version == 2 && swap.status == SwapStatus.PENDING;

  return <WatchSwapContainer>
    <MainContainer>
      <InfoContainer>
        <Trans>🙏Sorry, due to a system issue, videos may lag in Safari. For better playback, please use Chrome on your PC.</Trans>
      </InfoContainer>
      {isGeneratedAndLoading && <CircleLoading />}
      {!isGeneratedAndLoading && <SwapPlayer swap={swap} generating={generating} />}
      <SwapDescription swap={swap} />
      {userData?.downloadable && swap?.version == 2 && swap?.status != SwapStatus.GENERATED && "Generating..."}
      {userData?.downloadable && swap?.version == 2 && swap?.status == SwapStatus.GENERATED && <a href={`https://storages.work/users/${swap.userId}/streamings/${swap.streamingId}/video.mp4`}>Download</a>}

      {(haveIssue === undefined || haveIssue === null) && <ReportButton disabled={haveIssue === null} onClick={handleReportIssue}>
        {haveIssue === undefined && <Trans>Report an issue</Trans>}
        {haveIssue === null && <MiniCircleLoading />}
        {haveIssue === null && <Trans>Loading...</Trans>}
      </ReportButton>}
      {haveIssue === false && <Trans>It has been confirmed that there are no problems with video playback. If you are still experiencing issues, please check again in 30 minutes.</Trans>}
    </MainContainer>
    <SideContainer>
      <SwapList watchingSwapId={swapId} />
    </SideContainer>
  </WatchSwapContainer>;
};

export default WatchSwapPage;
