import { Timestamp } from "firebase/firestore";
import { ReactNode, useEffect } from "react";

export const useAsyncEffect = (asyncFunc: () => Promise<(() => void)|void>, vars: any[]) => {
  useEffect(() => {
    let unsubscribe: (() => void)|void = undefined;
    const execute = async () => {
      unsubscribe = await asyncFunc();
    };
    execute();
    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, vars);
};

export function throttle<T extends (...args: any[]) => void>(func: T, limit: number): T {
  let inThrottle: boolean;
  return function(this: ThisParameterType<T>, ...args: Parameters<T>): void {
    const context = this;
    if (!inThrottle) {
      func.apply(context, args);
      inThrottle = true;
      setTimeout(() => (inThrottle = false), limit);
    }
  } as T;
}

export function formatTime(seconds: number): string {
  seconds = Math.floor(seconds);
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;
  
  let formattedTime = '';
  
  if (hours > 0) {
    formattedTime += hours + ' hours ';
  }
  
  if (minutes > 0) {
    formattedTime += minutes + ' min ';
  }
  
  if (remainingSeconds > 0 || formattedTime === '') {
    formattedTime += remainingSeconds + ' sec';
  }
  
  return formattedTime;
}

export function formatDate(firestoreTimestamp: Timestamp): string {
  const jsDate = firestoreTimestamp.toDate();

  const year = jsDate.getFullYear();
  const month = String(jsDate.getMonth() + 1).padStart(2, '0');
  const day = String(jsDate.getDate()).padStart(2, '0');

  return `${year}/${month}/${day}`;
}

export function removeDuplicates<T>(arr: T[], key: (item: T) => any): T[] {
  const uniqueSet = new Set();
  return arr.filter((item) => {
    if (uniqueSet.has(key(item))) {
      return false;
    } else {
      uniqueSet.add(key(item));
      return true;
    }
  });
}

export const isMobile = () => /iPhone|iPad|iPod|Android/i.test(window?.navigator?.userAgent);

export type FCN<P = {}> = (props: P) => ReactNode|void;
