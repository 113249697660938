import { FC, useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { DetectedFace, DetectedOriginal, TemporarySwap, UserState } from "../ApiTypes";
import { getPaymentEmail, getTemporarySwap, processTemporarySwap } from "../api";
import { UserStateContext } from "../contexts/UserStateContext";
import { CircleLoading } from "../styles/Loadings";
import { useAsyncEffect } from "../utils";
import LoginPage from "./LoginPage";
import NewSwapPage from "./NewSwapPage";

enum Status {
    LOADING_TMP_SWAP,
    NOT_FOUND_TMP_SWAP,
    CANCELED_PAYMENT,
    SHOULD_SIGN_UP,
    SHOULD_PROCESS_TMP_SWAP
}

function getStatus(loggedin: boolean|undefined, canceled: boolean|undefined, tmpSwap: TemporarySwap|null|undefined, userState: UserState) {
    if (tmpSwap === undefined || userState.isLoading) {
        return Status.LOADING_TMP_SWAP;
    }
    if (canceled || localStorage.getItem("temporarySwapCanceled")) {
        localStorage.setItem("temporarySwapCanceled", "true");
        return Status.CANCELED_PAYMENT;
    }
    if (!loggedin && !userState.isSigned) {
        return Status.SHOULD_SIGN_UP;
    }

    if (tmpSwap == null) {
        return Status.NOT_FOUND_TMP_SWAP;
    }

    if (userState.isSigned) {
        return Status.SHOULD_PROCESS_TMP_SWAP;
    }
    return Status.LOADING_TMP_SWAP;
};

// loggedin: userStateの読み込み前にログイン状態かを表すためのフラグ
const TemporarySwapPage: FC<{ loggedin?: boolean, canceled?: boolean }> = (props) => {
    const { tmpSwapId } = useParams();
    const [email, setEmail] = useState<string|null|undefined>(undefined);
    const navigate = useNavigate();
    const userState = useContext(UserStateContext);
    const [tmpSwap, setTmpSwap] = useState<TemporarySwap|null|undefined>(undefined);

    const status = getStatus(props.loggedin, props.canceled, tmpSwap, userState);
    console.log(status);

    useAsyncEffect(async () => {
        setTmpSwap(await getTemporarySwap(tmpSwapId as string));
    }, [tmpSwapId]);

    // ログインしていない場合、Stripeからメールアドレスを取得
    useAsyncEffect(async () => {
        if (!tmpSwapId || status != Status.SHOULD_SIGN_UP) {
            return;
        }
        setEmail(await getPaymentEmail(tmpSwapId));
    }, [status]);

    // ログインしている場合、Swapを処理
    useAsyncEffect(async () => {
        console.log("processTemporarySwap")
        if (!tmpSwapId || status != Status.SHOULD_PROCESS_TMP_SWAP) {
            return;
        }
        console.log("processTemporarySwap2")
        try {
            await processTemporarySwap(tmpSwapId);
        } catch (error) {
            console.log(error);
        }
        localStorage.removeItem("temporarySwap");
        navigate(`/swaps/${tmpSwapId}?generating=1`);
    }, [status]);

    useEffect(() => {
        if (status == Status.NOT_FOUND_TMP_SWAP) {
            navigate(`/swaps/${tmpSwapId}?generating=1`);
            localStorage.removeItem("temporarySwap");
        }
    }, [status]);

    if (status == Status.CANCELED_PAYMENT && tmpSwap) {
        const original: DetectedOriginal = {
            url: tmpSwap.swapRequest.originalUrl,
            detected: {
                imageUrl: tmpSwap.swapRequest.originalThumbnailUrl,
                title: tmpSwap.swapRequest.originalTitle
            }
        };
        const face: DetectedFace = {
            userId: undefined,
            faceId: undefined,
            croppedFace: tmpSwap.swapRequest.croppedFace,
            meshedFace: tmpSwap.swapRequest.meshedFace
        };
        return <NewSwapPage original={original} face={face} />
    } if (status == Status.SHOULD_SIGN_UP && email !== undefined) {
        return <LoginPage email={email || ''} loginUrl={`${window.location.origin}/temporary_swaps_loggedin/${tmpSwapId}`} />
    } else {
        return <CircleLoading />
    }
};

export default TemporarySwapPage;
