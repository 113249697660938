import { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { DetectedFace, DetectedOriginal } from '../ApiTypes';
import GenerateSwap from '../components/GenerateSwap';
import SetFace from '../components/setup/SetFace';
import SetOriginal from '../components/setup/SetOriginal';
import { StepContainer, StepContent, StepTitle, StepTitleBar, Visibility } from '../styles/Containers';
import { InspireMessage } from '../styles/Messages';
import { Trans } from 'react-i18next';

const CenterContainer = styled.div`
  @media (min-width: 560px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;

const NewVideoContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 0px 20px;
  margin-bottom: 100px;

  @media (min-width: 800px) {
    min-width: 720px;
    max-width: 720px;
  }
`;

const GenerationTitle = styled.div`
  font-size: 26px;
  font-weight: bold;
  background: linear-gradient(to right, #ffa702, #ff5f02);
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
`;

const NotActiveGenerationTitle = styled.div`
  font-size: 26px;
  font-weight: bold;
`;

const PayButton = styled.span`
  margin-top: 10px;
  border-radius: 5px;
  height: 44px;

  background-color: rgb(254, 167, 0);
  color: black;
  padding: 0px 10px;

  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: bold;
  color: black;

  &:hover {
    background-color: #f8be53;
  }
`;

const GenerationContainer = styled.div`
`;

const NewSwapPage: FC<{ original?: DetectedOriginal, face?: DetectedFace }> = (props) => {
  const [original, setOriginal] = useState<DetectedOriginal|null>(props.original || null);
  const [face, setFace] = useState<DetectedFace|null>(props.face || null);
  const navigate = useNavigate();

  useEffect(() => {
    const tmpSwapId = localStorage.getItem("temporarySwap");
    if (tmpSwapId) {
      navigate(`/temporary_swaps/${tmpSwapId}`);
    }
  }, []);

  return <CenterContainer>
    <NewVideoContainer>
        <StepContainer>
          <StepTitle>
            <Trans>Face</Trans><StepTitleBar />
          </StepTitle>
          <StepContent>
            <SetFace initialFace={props.face} onChange={value => setFace(value)} />
          </StepContent>
        </StepContainer>
      
      <Visibility visible={face != null}>
        <StepContainer>
          <StepTitle>
            <Trans>Video</Trans><StepTitleBar />
          </StepTitle>
          <StepContent>
            <SetOriginal initialOriginal={props.original} onChange={original => setOriginal(original)} />
          </StepContent>
        </StepContainer>
      </Visibility>

      {original && face &&
        <GenerationContainer>
          <GenerationTitle>
            <Trans>Generation</Trans>
          </GenerationTitle>
          <StepContent>
            <InspireMessage>
              <Trans>Make your dream come true right away⚡️</Trans><br/>
              <Trans>Just wait 10 seconds to start streaming video.</Trans>
            </InspireMessage>
            <GenerateSwap original={original} face={face}/>
          </StepContent>
        </GenerationContainer>
      }
    </NewVideoContainer>
  </CenterContainer>;
};

export default NewSwapPage;
