import "./Loadings.css";

export const CircleLoading = () => {
  return <div className="loader"></div>
};

export const PlayCircleLoading = () => {
  return <div className="loader-play"></div>
};

export const MiniCircleLoading = () => {
  return <div className="loader_mini"></div>
};

export const EmptyCircleLoading = () => {
  return <div className="loader_empty"></div>
};
