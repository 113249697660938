import { FC, useContext, useState } from 'react';
import styled from 'styled-components';
import { DetectedOriginal } from '../../ApiTypes';
import { UserStateContext } from '../../contexts/UserStateContext';
import { Visibility } from '../../styles/Containers';
import { InspireMessage } from '../../styles/Messages';
import SlideTab from '../SlideTab';
import CreateOriginal from './CreateOriginal';
import SelectOriginal from './SelectOriginal';
import { Trans } from 'react-i18next';

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const SetOriginal: FC<{ initialOriginal?: DetectedOriginal, onChange(original: DetectedOriginal|null): void }> = (props) => {
  const [mode, setMode] = useState(0);
  const [newOriginal, setNewOriginal] = useState<DetectedOriginal|null>(props.initialOriginal || null);
  const [historyOriginal, setHistoryOriginal] = useState<DetectedOriginal|null>(null);
  const userState = useContext(UserStateContext);

  const changeMode = (mode: number) => {
    setMode(mode);
    props.onChange(mode == 0 ? newOriginal : historyOriginal);
  };

  const changeOnNew = (original: DetectedOriginal|null) => {
    setNewOriginal(original)
    props.onChange(original);
  };

  const changeOnHistory = (original: DetectedOriginal|null) => {
    setHistoryOriginal(original);
    props.onChange(original);
  };

  return <Container>
  <InspireMessage><Trans>Choose your favorite video with the best body and situation😍</Trans></InspireMessage>
    {userState.isSigned && <SlideTab tabs={["Import", "History"]} index={mode} onChange={mode => changeMode(mode)} />}
    <Visibility visible={mode == 0}><CreateOriginal original={newOriginal} onCreate={original => changeOnNew(original)} /></Visibility>
    {userState.isSigned && <Visibility visible={mode == 1}><SelectOriginal original={historyOriginal} onSelect={original => changeOnHistory(original)} updationKey={newOriginal} /></Visibility>}
  </Container>;
};

export default SetOriginal;
