import { FC } from 'react';
import { Trans } from 'react-i18next';
import styled from 'styled-components';

const Center = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
`;

const Container = styled.div`
  flex-direction: column;
  border-radius: 10px;
  // border: 1px solid #424242;
  background-color: #2b2b2b;
  color: white;
  padding: 30px;
  width: 500px;
  margin: 25px 20px 200px 20px;
  display: flex;
  font-weight: 500;
`;

const Title = styled.div`
  text-align: center;
  color: white;
  font-weight: 700;
  font-size: 22px;
  margin-bottom: 15px;
`;

const UsePasswordContainer = styled.div`
  text-align: right;
  margin-top: 5px;
  font-size: 14px;
  color: gray;
  cursor: pointer;
`;

const ReportIssuePage: FC<{}> = (props) => {
  return <Center>
    <Container>
      <Title>
        <Trans>Report an issue</Trans>
      </Title>

      <Trans>We have confirmed that there was a problem with video playback, so we have added one free credit for video generation. Please use this to generate a new video.</Trans>
    </Container>
  </Center>;
};

export default ReportIssuePage;
