
const SelectSubtitle = ({ ...others }) => {
  return (
    <svg
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...others}
    >
      <path
        d="M8.33333 30.5556V19.4444C8.33333 17.9167 9.58333 16.6667 11.1111 16.6667H19.4444C20.9722 16.6667 22.2222 17.9167 22.2222 19.4444V22.2222H18.0556V20.8333H12.5V29.1667H18.0556V27.7778H22.2222V30.5556C22.2222 32.0833 20.9722 33.3333 19.4444 33.3333H11.1111C9.58333 33.3333 8.33333 32.0833 8.33333 30.5556ZM30.5556 33.3333H38.8889C40.4167 33.3333 41.6667 32.0833 41.6667 30.5556V27.7778H37.5V29.1667H31.9444V20.8333H37.5V22.2222H41.6667V19.4444C41.6667 17.9167 40.4167 16.6667 38.8889 16.6667H30.5556C29.0278 16.6667 27.7778 17.9167 27.7778 19.4444V30.5556C27.7778 32.0833 29.0278 33.3333 30.5556 33.3333ZM47.2222 2.77778H2.77778V47.2222H47.2222V2.77778ZM50 0V50H0V0.0277778C0 -2.58701e-08 -2.58701e-08 0 0.0277778 0H50Z"
        fill="white"
      />
    </svg>
  );
};

export default SelectSubtitle;
